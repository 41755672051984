import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const Detail = ({ image, description, sx, ...rest }) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        ...sx,
      }}
      {...rest}
    >
      <GatsbyImage image={image} alt={description} />
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          bg: "primary",
          opacity: 0,
          display: "grid",
          placeItems: "center",
        }}
        framer
        whileHover={{ opacity: 0.7 }}
      >
        <Typography
          variant="normal"
          sx={{
            p: [3, 3, 5],
            textAlign: "center",
            color: "secondary",
            fontWeight: 700,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

export default Detail
