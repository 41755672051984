import React from "react"
import { useMotionValue, useSpring } from "framer-motion"
import { Box } from "~common/components/Primitives"

const Animation = ({
  animation,
  play = true,
  from = 0,
  to = 1,
  options,
  sx,
  ...rest
}) => {
  const Animation = require(`./${animation}`).default

  const control = useMotionValue(from)

  const time = useSpring(control, {
    mass: 1,
    stiffness: 1,
    damping: 1,
    ...options,
  })

  React.useEffect(() => {
    play ? control.set(to) : control.set(from)
  }, [control, play, from, to])

  return (
    <Box sx={{ ...sx }} {...rest}>
      <Animation time={time} width="100%" height="100%" />
    </Box>
  )
}

export default Animation
