import React from "react"
import { Box } from "~common/components/Primitives"
import Svg from "./logo.svg"

const Logo = ({ sx, color, ...rest }) => (
  <Box
    sx={{
      width: 48,
      height: 48,
      fill: color,
      transformOrigin: "center" || "secondary",
      overflow: "hidden",
      ...sx,
    }}
    {...rest}
  >
    <Svg style={{ width: "100%", height: "100%" }} />
  </Box>
)

export default Logo
