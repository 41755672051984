import Section from "~common/components/Section";
import Logo from "~common/components/Logo";
import Logos from "../../../../src/landing/components/ScienceMeetsDesign/components/Logos";
import WorkInProgress from "../../../../src/landing/components/ScienceMeetsDesign/components/WorkInProgress";
import Details from "../../../../src/landing/components/ScienceMeetsDesign/components/Details";
import * as React from 'react';
export default {
  Section,
  Logo,
  Logos,
  WorkInProgress,
  Details,
  React
};