import React from "react"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Line from "~common/components/Line"
import Logo from "~common/components/Logo"
import NasaBadge from "~common/components/NasaBadge"
import { BgImage } from "gbimage-bridge"

const Section = React.forwardRef(
  (
    {
      children,
      bg = "secondary",
      color = "primary",
      bgImageStyle = { backgroundPosition: "center" },
      sx,
      ...rest
    },
    ref
  ) => {
    if (typeof bg === "string") {
      return (
        <Box
          as="section"
          ref={ref}
          sx={{
            bg,
            color,
            ...sx,
          }}
          {...rest}
        >
          {children}
        </Box>
      )
    } else {
      return (
        <BgImage Tag="section" image={bg} style={{ ...bgImageStyle }}>
          <Box
            sx={{
              color,
              ...sx,
            }}
            {...rest}
          >
            {children}
          </Box>
        </BgImage>
      )
    }
  }
)

Section.Header = function SectionHeader({
  children,
  bg,
  color,
  logoColor,
  sx,
  ...rest
}) {
  let defaultLogoColor
  if (color === "accent") {
    defaultLogoColor = "softened-accent"
  } else if (color === "primary") {
    defaultLogoColor = "darkened-secondary"
  } else if (color === "secondary") {
    defaultLogoColor = "softened-secondary"
  }

  const containerRef = React.useRef()

  const [containerHeight, setContainerHeight] = React.useState(0)

  React.useEffect(() => {
    setContainerHeight(containerRef.current.offsetHeight)
  }, [])

  return (
    <Box
      ref={containerRef}
      sx={{
        px: [5, 6, 7],
        py: [5, 5, 6],
        minHeight: [120, 180, 240, 300],
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bg: bg || "none",
        ...sx,
      }}
      {...rest}
    >
      <Typography
        as="h1"
        variant="capitalized2"
        sx={{
          color,
          textAlign: "center",
          letterSpacing: "0.1em",
          wordSpacing: "0.5em",
          position: "relative",
          zIndex: 3,
        }}
        {...rest}
      >
        {children}
      </Typography>
      <Line
        color={color}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          mx: "auto",
          width: ["33%"],
        }}
      />
      <Logo
        color={logoColor || defaultLogoColor}
        sx={{
          width: 0.7 * containerHeight,
          height: 0.7 * containerHeight,
          maxWidth: [80, 120, 160, 200],
          maxHeight: [80, 120, 160, 200],
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0.25,
        }}
      />
    </Box>
  )
}

Section.Body = function SectionBody({ children, bg, color, sx, ...rest }) {
  return (
    <Box
      sx={{
        pb: [5, 6],
        bg,
        color,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

Section.Footer = function SectionFooter({ color, logo, nasaBadge }) {
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      {logo && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "absolute",
            bottom: 0,
            zIndex: 1,
            transform: "translateY(50%)translateY(-1px)",
          }}
        >
          <Line
            color={color}
            align="end"
            sx={{ width: [6, 6, 7], opacity: 0.7 }}
          />
          <Logo
            color={color}
            sx={{
              mx: [4, 5],
              width: [42, 64, 80],
              height: [42, 64, 80],
              transformOrigin: "center",
            }}
          />
          <Line
            color={color}
            align="start"
            sx={{ width: [6, 6, 7], opacity: 0.7 }}
          />
        </Box>
      )}
      {nasaBadge && (
        <NasaBadge
          color={color}
          sx={{
            position: "absolute",
            right: [4, 5],
            bottom: [2, 3, 4],
            zIndex: 3,
          }}
        />
      )}
    </Box>
  )
}

export default Section
