import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { alpha } from "@theme-ui/color"
import { Box } from "~common/components/Primitives"
import Detail from "./components/Detail"

const Details = () => {
  const {
    detail1,
    detail2,
    detail3,
    detail4,
    detail5,
    detail6,
    detail7,
    detail8,
  } = useStaticQuery(graphql`
    {
      workInProgress: file(relativePath: { eq: "work-in-progress.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      detail1: file(relativePath: { eq: "details/center-greek-alpha.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail2: file(relativePath: { eq: "details/research.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail3: file(relativePath: { eq: "details/header-arabic-beta.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail4: file(relativePath: { eq: "details/books.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail5: file(relativePath: { eq: "details/marker-arabic-alpha.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail6: file(
        relativePath: { eq: "details/modulation-greek-gamma.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail7: file(relativePath: { eq: "details/header-latin-alpha.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      detail8: file(relativePath: { eq: "details/inner-latin-beta.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
        gridGap: 1,
        bg: "secondary",
        px: 0,
        mt: 5,
        background: theme => `linear-gradient(
              to bottom,
              ${alpha("primary", 0)(theme)} 0%,
              ${theme.colors.primary} 16%,
              ${theme.colors.primary} 84%,
              ${alpha("primary", 0)(theme)} 100%
            )`,
      }}
    >
      <Detail image={getImage(detail1)} description="inner solar system" />
      <Detail
        image={getImage(detail6)}
        description="modulation of colors and strokes"
      />
      <Detail image={getImage(detail8)} description="scaling management" />
      <Detail
        image={getImage(detail4)}
        description="some inspirational books"
      />
      <Detail image={getImage(detail3)} description="arabic heading" />
      <Detail image={getImage(detail2)} description="developing and coding" />
      <Detail image={getImage(detail5)} description="end planet marker" />
      <Detail image={getImage(detail7)} description="latin heading" />
    </Box>
  )
}

export default Details
