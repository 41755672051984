import React from "react"
import useTheme from "~styles/hooks/useTheme"

const useMedia = breakpoint => {
  const { theme } = useTheme()
  const { breakpoints } = theme

  const [matches, setMatches] = React.useState(0)

  React.useEffect(() => {
    const media = window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`)
    if (media.matches !== matches) setMatches(media.matches)
    const listener = () => setMatches(media.matches)
    media.addEventListener("change", listener)
    return () => media.removeEventListener("change", listener)
  }, [breakpoint, breakpoints, matches])

  return matches
}

export default useMedia
