const buttons = {
  primary: {
    bg: theme => `${theme.colors.primary}`,
    color: "accent",
    pointerEvents: "auto",
    border: "1px solid",
    borderColor: "accent",
    "&:hover": {
      bg: "accent",
      color: "primary",
      borderColor: "primary",
    },
  },
  secondary: {
    bg: "secondary",
    color: "primary",
    pointerEvents: "auto",
    border: "1px solid",
    borderColor: "secondary",
    "&:hover": {
      bg: "primary",
      color: "secondary",
      borderColor: "secondary",
    },
  },
  accent: {
    bg: "accent",
    color: "primary",
    pointerEvents: "auto",
    border: "1px solid",
    borderColor: "primary",
    "&:hover": {
      bg: "primary",
      color: "accent",
      borderColor: "primary",
    },
  },
  disabled: {
    bg: "softened-secondary",
    color: "primary",
    pointerEvents: "none",
  },
  icon: {
    cursor: "auto",
  },
}

export default buttons
