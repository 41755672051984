import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import Cookies from "js-cookie"
import ls from "localstorage-ttl"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Button from "~common/components/Button"

const CookiesConsent = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const location = useLocation()

  const handleAccept = () => {
    ls.set("cookies", "accept", 1000 * 60 * 60 * 24 * 365)
    Cookies.set("gatsby-gdpr-google-tagmanager", true, {
      secure: true,
      sameSite: "None",
    })
    initializeAndTrack(location)
    setIsOpen(false)
  }

  const handleDecline = () => {
    ls.set("cookies", "decline", 1000 * 60 * 60 * 24)
    setIsOpen(false)
  }

  useEffect(() => {
    try {
      setIsOpen(!ls.get("cookies"))
    } catch {
      setIsOpen(true)
    }
  }, [setIsOpen])

  const isPrivacy = /\/privacy\/?/.test(location.pathname)

  return isOpen && !isPrivacy ? (
    <Box
      sx={{
        opacity: 0.9,
        zIndex: 1000,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        px: [4, 5],
        py: 3,
        bg: "secondary",
        display: "flex",
        flexDirection: ["column", "row"],
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="small" sx={{ flex: 1, color: "primary" }}>
        We use cookies to improve your experience on our site and support our
        mission. To find out more, checkout out our{" "}
        <Box
          as="span"
          sx={{
            a: {
              color: "softened-accent",
              "&:hover": { color: "accent" },
            },
          }}
        >
          <Link to="/privacy">Privacy Policy</Link>
        </Box>
        .
      </Typography>
      <Box
        sx={{
          flex: [1, 0.5, 0.3],
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: ["space-between", "flex-end"],
          alignItems: "center",
        }}
      >
        <Typography
          variant="small"
          as="button"
          aria-label="decline"
          onClick={handleDecline}
          sx={{
            color: "softened-accent",
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              color: "accent",
            },
          }}
        >
          Decline
        </Typography>
        <Button
          aria-label="accept"
          variant="primary"
          onClick={handleAccept}
          sx={{
            ml: [4, 5],
            minWidth: "auto",
            px: 4,
            py: 2,
            borderColor: "secondary",
            "&:hover": { borderColor: "accent" },
          }}
        >
          Accept
        </Button>
      </Box>
    </Box>
  ) : null
}

export default CookiesConsent
