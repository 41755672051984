import React from "react"
import { Link } from "gatsby"
import { useLayoutContext } from "~layout/contexts/layout"
import usePage from "~common/hooks/usePage"
import { useDrawerContext, provideDrawerContext } from "~common/contexts/drawer"
import { Box } from "~common/components/Primitives"
import Brand from "~common/components/Brand"
import NavbarNav from "~common/components/NavbarNav"
import Icon from "~common/components/Icon"
import Drawer from "~common/components/Drawer"
import Typography from "~common/components/Typography"

const Header = provideDrawerContext(({ sx, ...rest }) => {
  const { headerRef, headerCollapse } = useLayoutContext()

  const { isDrawerOpen, handleDrawerOpen, handleDrawerClose } =
    useDrawerContext()

  const isIndex = usePage("/")

  return (
    <>
      <Box
        ref={headerRef}
        as="header"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
          ...sx,
        }}
        {...rest}
      >
        <Box
          sx={{
            px: [4, 5],
            py: headerCollapse ? [3, 4] : [4, 5],
            transition: "all 300ms",
            bg:
              headerCollapse || isDrawerOpen || !isIndex
                ? "primary"
                : "#fefefe00",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <Brand />
          </Link>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                mr: [5, 5, 0],
                fill: "secondary",
                order: [0, 0, 2],
              }}
            >
              <Box
                sx={{
                  width: ["auto", "auto", 20],
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                }}
              >
                <Icon
                  as="button"
                  name="cart"
                  aria-label="cart"
                  className="snipcart-checkout"
                  sx={{ "&:hover": { fill: "accent", cursor: "pointer" } }}
                />
                <Typography
                  variant="tiny"
                  sx={{
                    color: "secondary",
                    position: "absolute",
                    top: "-0.5em",
                    left: "100%",
                  }}
                  className="snipcart-items-count"
                ></Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <NavbarNav />
              <Icon
                sx={{
                  display: ["static", "static", "none"],
                  "&:hover": {
                    cursor: "pointer",
                    fill: "accent",
                  },
                }}
                variant="button"
                name={isDrawerOpen ? "close" : "menu"}
                onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Drawer />
    </>
  )
})

export default Header
