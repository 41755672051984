import React from "react"
import { Box } from "~common/components/Primitives"
import Hero from "~landing/components/Hero"

const HeroSpaceGift = () => (
  <Hero
    renderTitle={() => "The perfect gift for space lovers"}
    renderCardTitle={() => "Custom Poster"}
    renderCardDescription1={() =>
      "An accurate astronomical map that displays the trajectories of the Planets and the Sun as they appear from one of them."
    }
    renderCardDescription2={() => (
      <>
        Customize the{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          reference planet
        </Box>
        , the{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          starting and ending dates
        </Box>
        , the{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          zoom level
        </Box>
        ,{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          color scheme
        </Box>
        , and optionally add your{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          personal message
        </Box>
        .
      </>
    )}
    renderCardDescription3={() =>
      "Impress your beloved science nerd with a tailor-made astronomical print built from JPL/NASA ephemerides."
    }
    renderCallToAction={() => "Create your Solar System Map"}
    renderBottomParagraph={() => (
      <>
        Celebrate something special through a{" "}
        <Box as="b" sx={{ fontWeight: 700 }}>
          personalized poster gift
        </Box>{" "}
        that unfolds the movements of the Solar System as they appear from one
        of its planets.
      </>
    )}
  />
)

export default HeroSpaceGift
