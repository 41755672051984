import React from "react";
import Layout from "~landing/components/Layout";
import HeroSpacePoster from "~landing/components/HeroSpacePoster";
import HowItWorks from "~landing/components/HowItWorks";
import Mockups from "~landing/components/Mockups";
import Testimonials from "~landing/components/Testimonials";
import ScienceMeetsDesign from "~landing/components/ScienceMeetsDesign";
import WhatMakesItSpecial from "~landing/components/WhatMakesItSpecial";
import AChangeOfPerspective from "~landing/components/AChangeOfPerspective";
export default {
  React,
  Layout,
  HeroSpacePoster,
  HowItWorks,
  Mockups,
  Testimonials,
  ScienceMeetsDesign,
  WhatMakesItSpecial,
  AChangeOfPerspective
};