import { graphql, useStaticQuery } from "gatsby"

const useImage = path => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { glob: "**/*.(png|jpg)" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativePath
          }
        }
      }
    }
  `)

  const images = Object.assign(
    {},
    ...data.allFile.edges.map(({ node }) => ({
      [node.relativePath]: node.childImageSharp.gatsbyImageData,
    }))
  )

  return images[path]
}

export default useImage
