import React from "react"
import ls from "localstorage-ttl"
import { Box, Input, Label } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"
import NewsletterForm from "~newsletter/components/NewsletterForm"

const TextInput = ({ color, sx, ...rest }) => (
  <Input
    sx={{
      minHeight: 48,
      border: "none",
      borderBottom: theme => `1px solid ${theme.colors[color]}`,
      borderRadius: 0,
      "&::placeholder": {
        opacity: 0.8,
        fontSize: "0.8rem",
        color,
      },
      ...sx,
    }}
    {...rest}
  />
)

export default React.forwardRef(({ color = "primary", sx, ...rest }, ref) => {
  const handleSubmit = () => {
    ls.set("newsletter", "signed", 1000 * 60 * 60 * 24 * 365)
    if (window.fbq != null) {
      window.fbq("track", "Complete Registration")
    }
  }

  return (
    <NewsletterForm
      ref={ref}
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <Typography variant="heading2" color={color}>
        Join our newsletter
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              mx: 2,
              flex: 1,
            }}
          >
            <Label
              htmlFor="mce-FNAME"
              sx={{
                border: 0,
                clip: "rect(0, 0, 0, 0)",
                height: "1px",
                margin: "-1px",
                overflow: "hidden",
                padding: 0,
                position: "absolute",
                width: "1px",
              }}
            >
              Name
            </Label>
            <TextInput
              color={color}
              name="FNAME"
              className="required"
              id="mce-FNAME"
              type="text"
              placeholder="Your name..."
            />
          </Box>
          <Box
            sx={{
              mx: 2,
              bg: "rgba(0, 0, 0, 0)",
              flex: 2,
            }}
          >
            <Label
              htmlFor="mce-EMAIL"
              sx={{
                border: 0,
                clip: "rect(0, 0, 0, 0)",
                height: "1px",
                margin: "-1px",
                overflow: "hidden",
                padding: 0,
                position: "absolute",
                width: "1px",
              }}
            >
              email
            </Label>
            <TextInput
              color={color}
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              placeholder="Your email..."
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              left: "102%",
              m: 4,
              fill: color,
              color: color,
              cursor: "pointer",
              "&:hover": { fill: "accent", color: "accent" },
            }}
            as="button"
            aria-label="submit"
            type="submit"
            defaultValue="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button"
          >
            <Typography
              variant="heading3"
              as="span"
              sx={{
                color: "inherit",
                pointerEvents: "none",
              }}
            >
              send
            </Typography>
            <Icon
              type="submit"
              name="arrow-right"
              sx={{
                pointerEvents: "none",
                fill: "inherit",
              }}
            />
          </Box>
        </Box>
      </Box>
    </NewsletterForm>
  )
})
