import React, { useState, useRef, useLayoutEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useViewportScroll, useSpring, useTransform } from "framer-motion"
import useWindowSize from "~common/hooks/useWindowSize"
import { Box } from "~common/components/Primitives"

const Parallax = ({
  children,
  bgImage,
  bgImageAlt,
  bgImageStyle,
  visible,
  sx,
  ...rest
}) => {
  const { height: windowHeight } = useWindowSize()

  const { scrollY } = useViewportScroll()

  const [offsetTop, setOffsetTop] = useState(0)

  const imageRef = useRef()

  const [imageHeight, setImageHeight] = useState(0)

  const measureOffsetTop = () => {
    if (imageRef.current) {
      setOffsetTop(
        imageRef.current.getBoundingClientRect().top + window.pageYOffset
      )
    }
  }

  const measureImageHeight = () => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight)
    }
  }

  const handleImageLoad = () => {
    measureImageHeight()
    measureOffsetTop()
  }

  useLayoutEffect(() => {
    measureOffsetTop()
    window.addEventListener("resize", measureOffsetTop)
    return () => window.removeEventListener("resize", measureOffsetTop)
  }, [])

  useLayoutEffect(() => {
    measureImageHeight()
    window.addEventListener("resize", measureImageHeight)
    return () => window.removeEventListener("resize", measureImageHeight)
  }, [])

  const y = useSpring(
    useTransform(
      scrollY,
      [offsetTop - windowHeight, offsetTop],
      [((1 - visible) / 2) * imageHeight, -((1 - visible) / 2) * imageHeight]
    ),
    {
      mass: 1,
      stiffness: 1000,
      damping: 100,
    }
  )

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        display: "grid",
        placeItems: "center",
        height: visible * imageHeight,
        ...sx,
      }}
      {...rest}
    >
      <Box
        framer
        style={{ y }}
        ref={imageRef}
        sx={{
          width: "100%",
          position: "absolute",
          ...bgImageStyle,
        }}
      >
        <GatsbyImage
          image={bgImage}
          alt={bgImageAlt}
          onLoad={handleImageLoad}
        />
      </Box>
      <Box sx={{ zIndex: 1 }}>{children}</Box>
    </Box>
  )
}

export default Parallax
