import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Section from "~common/components/Section"
import Typography from "~common/components/Typography"
import ProductDetails from "~shop/components/ProductDetails"
import useTheme from "~styles/hooks/useTheme"
import useMedia from "~common/hooks/useMedia"

const Hero = ({
  renderTitle,
  renderCardTitle,
  renderCardDescription1,
  renderCardDescription2,
  renderCardDescription3,
  renderCallToAction,
  renderBottomParagraph,
}) => {
  const { desert } = useStaticQuery(graphql`
    {
      desert: file(
        relativePath: { eq: "celebrate-your-time/almagesto-desert-big.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 75)
        }
      }
    }
  `)

  const { theme } = useTheme()

  const isAtLeastMd = useMedia("md")
  const isAtLeastLg = useMedia("lg")

  return (
    <Section
      bg={getImage(desert)}
      bgImageStyle={{
        backgroundPosition: isAtLeastLg
          ? "center bottom -18vw"
          : isAtLeastMd
          ? "center bottom -15vw"
          : "center bottom -36vw",
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: [
          `linear-gradient(to bottom, ${theme.colors.secondary} 0%, ${theme.colors.secondary} 20%, transparent 25%)`,
          `linear-gradient(to bottom, ${theme.colors.secondary} 0%, ${theme.colors.secondary} 20%, transparent 50%)`,
          `linear-gradient(to bottom, ${theme.colors.secondary} 0%, ${theme.colors.secondary} 10%, transparent 50%)`,
        ],
      }}
    >
      <Section.Header
        color="primary"
        logoColor="softened-secondary"
        sx={{
          py: [4, 5],
          minHeight: [120, 180],
        }}
      >
        {renderTitle()}
      </Section.Header>
      <Section.Body
        sx={{
          my: 0,
          pb: 0,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bg: "transparent",
        }}
      >
        <ProductDetails
          initialProductId="custom_poster_50x70cm"
          sx={{
            bg: "primary",
            mx: [3, 4, "4%", "8%", "16%"],
            px: 0,
            py: 0,
            "@media screen and (min-width: 960px) and (max-width: 1080px)": {
              mx: 0,
            },
            "@media screen and (min-width: 1280px) and (max-width: 1400px)": {
              mx: "5%",
            },
          }}
        >
          <ProductDetails.Image style={{ gridArea: "gallery" }} />
          <ProductDetails.Info
            sx={{
              pt: [0, 0, 4, 5],
              pb: [0, 0, 5, 5],
              ml: [3, 4, 4, 5],
              mr: [3, 4, 5, 6],
              "@media screen and (min-width: 1280px) and (max-width: 1400px)": {
                pt: 4,
                pb: 5,
                ml: 4,
                mr: 5,
              },
            }}
          >
            <Box sx={{ width: "100%", display: ["auto", "auto", "none"] }}>
              <ProductDetails.Selector
                sx={{
                  my: 4,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                productsMap={{
                  custom_poster_30x40cm: "small",
                  custom_poster_50x70cm: "medium",
                  custom_poster_61x91cm: "large",
                }}
              />
              <ProductDetails.PriceTag cart sx={{ mt: 4 }} />
              <ProductDetails.Description
                sx={{ color: "secondary", textAlign: "left", mt: 4 }}
              >
                {renderCardDescription1()}
              </ProductDetails.Description>
              <Box as="ul" sx={{ mt: [4] }}>
                <ProductDetails.Feature as="li" icon="shipping">
                  FREE WORLDWIDE SHIPPING (7-14 days)
                </ProductDetails.Feature>
                <ProductDetails.Feature as="li" icon="ruler">
                  <ProductDetails.Size />
                </ProductDetails.Feature>
                <ProductDetails.Feature as="li" icon="paper">
                  Printed on Enhanced Matte Paper
                </ProductDetails.Feature>
                <ProductDetails.Feature as="li" icon="eco">
                  Toxin-free, Biodegradable, Water-based Ink
                </ProductDetails.Feature>
              </Box>
              <Box as="ul" sx={{ mt: [4] }}>
                <ProductDetails.Note as="li">
                  no frame included
                </ProductDetails.Note>
              </Box>
              <ProductDetails.Description
                sx={{ color: "secondary", textAlign: "left", mt: 4 }}
              >
                {renderCardDescription2()}
              </ProductDetails.Description>
              <ProductDetails.Description
                sx={{ color: "secondary", textAlign: "left", mt: 4 }}
              >
                {renderCardDescription3()}
              </ProductDetails.Description>
              <ProductDetails.Cta
                gtmParams={{ event: "configurator_init" }}
                sx={{
                  my: [4, 5],
                  justifyContent: "center",
                }}
              >
                {renderCallToAction()}
              </ProductDetails.Cta>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: ["none", "none", "flex"],
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                as="h2"
                variant="capitalized3"
                sx={{
                  color: "secondary",
                  textAlign: "left",
                  fontWeight: 700,
                }}
              >
                {renderCardTitle()}
              </Typography>
              <ProductDetails.Description
                sx={{
                  color: "secondary",
                  textAlign: "left",
                  mt: [3, 3, 4],
                  "@media screen and (min-width: 960px) and (max-width: 1080px)":
                    {
                      mt: 3,
                    },
                }}
              >
                {renderCardDescription1()}
              </ProductDetails.Description>
              <ProductDetails.Description
                sx={{
                  color: "secondary",
                  textAlign: "left",
                  mt: [3, 3, 4],
                  "@media screen and (min-width: 960px) and (max-width: 1080px)":
                    {
                      mt: 3,
                    },
                }}
              >
                {renderCardDescription2()}
              </ProductDetails.Description>
              <ProductDetails.Description
                sx={{
                  color: "secondary",
                  textAlign: "left",
                  mt: [3, 3, 4],
                  "@media screen and (min-width: 960px) and (max-width: 1080px)":
                    {
                      mt: 3,
                    },
                }}
              >
                {renderCardDescription3()}
              </ProductDetails.Description>
              <Box as="ul" sx={{ mt: [3, 3, 4] }}>
                <ProductDetails.Feature as="li" icon="shipping">
                  FREE WORLDWIDE SHIPPING (7-14 days)
                </ProductDetails.Feature>
                <ProductDetails.Feature as="li" icon="ruler">
                  <ProductDetails.Size />
                </ProductDetails.Feature>
                <ProductDetails.Feature as="li" icon="paper">
                  Printed on Enhanced Matte Paper
                </ProductDetails.Feature>
                <ProductDetails.Feature as="li" icon="eco">
                  Toxin-free, Biodegradable, Water-based Ink
                </ProductDetails.Feature>
              </Box>
              <Box
                as="ul"
                sx={{ mt: 4, display: ["none", "none", "none", "block"] }}
              >
                <ProductDetails.Note as="li">
                  no frame included
                </ProductDetails.Note>
              </Box>
              <ProductDetails.Selector
                sx={{
                  my: [3, 3, 4],
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                productsMap={{
                  custom_poster_30x40cm: "small",
                  custom_poster_50x70cm: "medium",
                  custom_poster_61x91cm: "large",
                }}
              />
              <ProductDetails.PriceTag
                cart={false}
                sx={{
                  mt: [0, 0, 0, 4, 5],
                }}
              />
            </Box>
          </ProductDetails.Info>
          <ProductDetails.Cta
            gtmParams={{ event: "configurator_init" }}
            sx={{
              display: ["none", "none", "block"],
              alignSelf: "end",
              justifySelf: "center",
              gridRow: "1 / 2",
              gridColumn: ["auto", "auto", "1 / 3"],
              transform: "translateY(50%)",
            }}
          >
            {renderCallToAction()}
          </ProductDetails.Cta>
        </ProductDetails>
        <Typography
          variant="normal"
          sx={{
            color: "primary",
            textAlign: "center",
            mt: [4, 5, 6],
            mb: [6, 7],
            mx: [4, "9%", "16%"],
            maxWidth: [500, 500, 640],
          }}
        >
          {renderBottomParagraph()}
        </Typography>
      </Section.Body>
      <Section.Footer logo nasaBadge color="primary" />
    </Section>
  )
}

export default Hero
