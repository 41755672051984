import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { H1, H2, H3, P, Ul, Li } from "~common/components/Elements"

const Mdx = ({ children }) => (
  <MDXProvider components={{ h1: H1, h2: H2, h3: H3, p: P, ul: Ul, li: Li }}>
    {children}
  </MDXProvider>
)

export default Mdx
