import React from "react"
import { Link } from "gatsby"
import { useLayoutContext } from "~layout/contexts/layout"
import { Box } from "~common/components/Primitives"
import Brand from "~common/components/Brand"
import useTheme from "~styles/hooks/useTheme"

const Header = ({ sx, ...rest }) => {
  const { headerRef, headerCollapse } = useLayoutContext()

  const { theme } = useTheme()

  return (
    <Box
      ref={headerRef}
      as="header"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          px: [3, 4],
          py: headerCollapse ? 0 : [3, 4],
          opacity: headerCollapse ? [1] : [0, 1],
          transition: "all 500ms",
          bg: headerCollapse ? ["primary"] : ["transparent"],
          display: "flex",
          justifyContent: headerCollapse ? ["center"] : ["flex-start"],
          alignItems: "center",
        }}
      >
        <Link to="/">
          <Brand
            framer
            layout
            initial="rest"
            animate={headerCollapse ? "collapse" : "rest"}
            transition={{ duration: 0.1, type: "tween" }}
            // color={headerCollapse ? ["secondary"] : ["secondary", "primary"]}
            logoProps={{
              animate: headerCollapse ? "collapse" : "rest",
            }}
            logoVariants={{
              collapse: {
                fill: theme.colors.secondary,
                rotate: "90deg",
                scale: 0.7,
                transition: {
                  type: "spring",
                  stiffness: 1000,
                  mass: 50,
                  damping: 500,
                },
              },
              rest: {
                fill: theme.colors.primary,
                rotate: "0deg",
                scale: 1,
                transition: {
                  type: "spring",
                  stiffness: 1000,
                  mass: 50,
                  damping: 500,
                },
              },
            }}
            textProps={{
              sx: { ml: [0, 2, 4] },
            }}
            letterVariants={i => ({
              initial: {
                color: theme.colors.primary,
                opacity: 0,
              },
              rest: {
                opacity: 1,
                color: theme.colors.primary,
                transition: {
                  delay: 0.05 * i,
                },
              },
              collapse: {
                opacity: 1,
                color: theme.colors.secondary,
                transition: {
                  delay: 0.05 * i,
                },
              },
            })}
            letterProps={{
              animate: headerCollapse ? "collapse" : "rest",
            }}
          />
        </Link>
      </Box>
    </Box>
  )
}

export default Header
