import React from "react"
import { Box } from "~common/components/Primitives"

export default React.forwardRef(({ children, sx, ...rest }, ref) => {
  return (
    <Box
      ref={ref}
      as="form"
      action="https://xyz.us2.list-manage.com/subscribe/post?u=6c78b6e3522a77918177ea989&id=29e747dc38"
      method="post"
      target="_blank"
      rel="noreferrer"
      noValidate
      sx={{ ...sx }}
      {...rest}
    >
      {children}
    </Box>
  )
})
