import React from "react"
import { Link } from "gatsby"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import { pushToGtm } from "~common/utils/gtm"

const Cta = ({ children, to, gtmParams, sx, ...rest }) => {
  const handleClick = () => {
    pushToGtm({ event: "cta_button_click", ...gtmParams })
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: "inline-block",
        px: [4, 5],
        py: [4],
        bg: "accent",
        color: "primary",
        cursor: "pointer",
        pointerEvents: "auto",
        border: theme => `1px solid ${theme.colors.accent}`,
        borderRadius: "256px",
        "&:hover": {
          bg: "primary",
          color: "accent",
          borderColor: "primary",
          borderStyle: "dashed",
          boxShadow: theme =>
            `0px 0px 12px 2px ${theme.colors["softened-accent"]}`,
          a: {
            color: "accent",
            textDecoration: "underline",
          },
        },
        a: {
          color: "primary",
        },
        ...sx,
      }}
      {...rest}
    >
      <Link to={to}>
        <Typography
          as="span"
          variant="capitalized3"
          sx={{
            display: "block",
            textAlign: "center",
            position: "relative",
            top: "0.1em",
          }}
        >
          {children}
        </Typography>
      </Link>
    </Box>
  )
}

export default Cta
