import React from "react"
import { LayoutContextProvider } from "~layout/contexts/layout"
import CookiesConsent from "~common/components/CookiesConsent"
import Header from "~layout/components/Header"
import SideBar from "~layout/components/SideBar"
import Main from "~layout/components/Main"
import Footer from "~layout/components/Footer"

const Layout = ({ children, pageContext }) => {
  return (
    <LayoutContextProvider>
      {pageContext.layout === "landing" ? (
        children
      ) : (
        <>
          <CookiesConsent />
          <Header />
          <SideBar />
          <Main>{children}</Main>
          <Footer />
        </>
      )}
    </LayoutContextProvider>
  )
}

export default Layout
