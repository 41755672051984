import React from "react"
import useProducts from "~shop/hooks/useProducts"
import ProductContext from "./context"

const ProductContextProvider = ({ initialProductId, children }) => {
  const { getProductById } = useProducts()

  const [product, setProduct] = React.useState(getProductById(initialProductId))

  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0)

  const selectedImage = product.gallery[selectedImageIndex]

  const selectProductById = productId => {
    setSelectedImageIndex(0)
    setProduct(getProductById(productId))
  }

  return (
    <ProductContext.Provider
      value={{
        product,
        selectedImageIndex,
        setSelectedImageIndex,
        selectedImage,
        selectProductById,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const provideProductContext =
  Component =>
  ({ initialProductId, ...rest }) =>
    (
      <ProductContextProvider initialProductId={initialProductId}>
        <Component {...rest} />
      </ProductContextProvider>
    )

export default ProductContextProvider
