import React from "react"
import { Box } from "~common/components/Primitives"

const Video = ({ src, sx, ...rest }) => (
  <Box
    sx={{
      maxWidth: [640, 680, 720, 780],
      mx: "auto",
      ...sx,
    }}
    {...rest}
  >
    <video width="100%" autoPlay muted controls>
      <source src={src} />
      <track kind="caption" />
    </video>
  </Box>
)

export default Video
