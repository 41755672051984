import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const Event = ({ children, image, alt, sx, ...rest }) => (
  <Box
    sx={{
      width: "100%",
      position: "relative",
      ...sx,
    }}
    {...rest}
  >
    <GatsbyImage
      image={image}
      alt={alt}
      sizes="(max-width: 960px) 33vw, 33vw"
    />
    <Typography
      variant="capitalized3"
      as="h2"
      sx={{
        color: "accent",
        position: "absolute",
        top: "12%",
        width: "100%",
        textAlign: "center",
        opacity: 1,
      }}
    >
      {children}
    </Typography>
  </Box>
)

export default Event
