import React from "react"
import Typography from "~common/components/Typography"

export const H1 = ({ children }) => (
  <Typography
    as="h1"
    variant="heading2"
    sx={{
      color: "accent",
      fontWeight: 700,
      maxWidth: [640, 680, 720, 780],
      mx: "auto",
      mt: [4, 5],
      mb: [3, 4],
      px: [4, 5, 0],
    }}
  >
    {children}
  </Typography>
)

export const H2 = ({ children }) => (
  <Typography
    as="h2"
    variant="heading3"
    sx={{
      fontWeight: 700,
      maxWidth: [640, 680, 720, 780],
      mx: "auto",
      mt: [4, 5],
      mb: [3, 4],
      px: [4, 5, 0],
    }}
  >
    {children}
  </Typography>
)

export const H3 = ({ children }) => (
  <Typography
    as="h3"
    variant="heading3"
    sx={{
      maxWidth: [640, 680, 720, 780],
      mx: "auto",
      mt: [4, 5],
      mb: [3, 4],
      px: [4, 5, 0],
    }}
  >
    {children}
  </Typography>
)

export const P = ({ children }) => (
  <Typography
    as="p"
    variant="normal"
    sx={{
      textAlign: "justify",
      maxWidth: [640, 680, 720, 780],
      mx: "auto",
      my: [3, 4],
      px: [4, 5, 0],
    }}
  >
    {children}
  </Typography>
)

export const Ul = ({ children }) => (
  <Typography
    as="ul"
    variant="normal"
    sx={{
      textAlign: "justify",
      maxWidth: [640, 680, 720, 780],
      mx: "auto",
      my: [3, 4],
      px: [4, 5, 0],
    }}
  >
    {children}
  </Typography>
)

export const Li = ({ children }) => (
  <Typography
    as="li"
    variant="normal"
    sx={{
      textAlign: "justify",
      maxWidth: [640, 680, 720, 780],
      "&::before": {
        content: '"*"',
        color: "secondary",
        mx: "auto",
        px: [4, 5, 0],
      },
    }}
  >
    {children}
  </Typography>
)
