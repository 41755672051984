import React from "react"
import { useThemeUI } from "theme-ui"
import { Global, css } from "@emotion/react"

const GlobalStyles = () => {
  const { theme } = useThemeUI()
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

        html,
        body {
          background: ${theme.colors.primary};
          color: ${theme.colors.secondary};
          fill: ${theme.colors.secondary};
          font-family: ${theme.fonts.body};
          font-size: 17px;
          font-weight: 400;
        }

        body {
          width: 100%;
          overflow-x: hidden;
        }

        a {
          text-decoration: none;
          color: ${theme.colors.accent};
          &:hover {
            color: ${theme.colors["softened-secondary"]};
          }
        }

        input,
        select,
        textarea,
        button {
          font-family: inherit;
        }

        ul {
          list-style: none;
        }

        button {
          display: inline-block;
          border: none;
          text-decoration: none;
          background: none;
          outline: none;
          cursor: pointer;
        }

        input,
        select {
          border: none;
          outline: none;
          appearance: none;
          font-family: ${theme.fonts.body};
          font-size: 16px;
        }

        option {
          padding: 16px;
        }

        figcaption {
          color: ${theme.colors["softened-secondary"]};
          font-size: 0.8rem;
          text-align: center;
        }

        ${theme.mediaQueries.sm} {
          html,
          body,
          input {
            font-size: 17px;
          }
        }

        ${theme.mediaQueries.md} {
          html,
          body,
          input {
            font-size: 18px;
          }
        }

        ${theme.mediaQueries.lg} {
          html,
          body,
          input {
            font-size: 19px;
          }
        }
      `}
    />
  )
}

export default GlobalStyles
