import React from "react"
import { Button as ButtonPrimitive } from "~common/components/Primitives"

const Button = ({ children, sx, ...rest }) => (
  <ButtonPrimitive
    sx={{
      minWidth: [96, 96, 128],
      px: [4, 5],
      py: [2, 3],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "256px",
      transition: "all 250ms",
      ...sx,
    }}
    {...rest}
  >
    {children}
  </ButtonPrimitive>
)

export default Button
