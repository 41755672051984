import React from "react"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const PriceTag = ({ price, discount, decimals = 0, sx, ...rest }) => {
  const originalPrice = price / (1 - discount / 100)
  return (
    <Box
      as="span"
      sx={{
        display: "flex",
        alignItems: "baseline",
        whiteSpace: "no-wrap",
        ...sx,
      }}
      {...rest}
    >
      {!!discount && (
        <Typography
          as="span"
          variant="small"
          sx={{
            color: "softened-secondary",
            fontWeight: 700,
            textDecoration: "line-through",
            position: "relative",
            whiteSpace: "no-wrap",
          }}
        >
          €{originalPrice.toFixed(Number.isInteger(price) ? decimals : 2)}
          <Typography
            as="span"
            variant="tiny"
            sx={{
              color: "softened-accent",
              fontWeight: 700,
              position: "absolute",
              textDecoration: "none",
              right: 0,
              top: "-64%",
            }}
          >{`-${discount}%`}</Typography>
        </Typography>
      )}
      <Typography
        as="span"
        variant="large"
        sx={{ color: "accent", fontWeight: 700, ml: discount ? 4 : 0 }}
      >
        €{price.toFixed(Number.isInteger(price) ? decimals : 2)}
      </Typography>
    </Box>
  )
}

export default PriceTag
