exports.components = {
  "component---src-blog-templates-post-post-js": () => import("./../../../src/blog/templates/Post/Post.js" /* webpackChunkName: "component---src-blog-templates-post-post-js" */),
  "component---src-landing-templates-landing-landing-js": () => import("./../../../src/landing/templates/Landing/Landing.js" /* webpackChunkName: "component---src-landing-templates-landing-landing-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-configurator-js": () => import("./../../../src/pages/configurator.js" /* webpackChunkName: "component---src-pages-configurator-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-payment-mdx": () => import("./../../../src/pages/payment.mdx" /* webpackChunkName: "component---src-pages-payment-mdx" */),
  "component---src-pages-presskit-js": () => import("./../../../src/pages/presskit.js" /* webpackChunkName: "component---src-pages-presskit-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-project-mdx": () => import("./../../../src/pages/project.mdx" /* webpackChunkName: "component---src-pages-project-mdx" */),
  "component---src-pages-refunds-mdx": () => import("./../../../src/pages/refunds.mdx" /* webpackChunkName: "component---src-pages-refunds-mdx" */),
  "component---src-pages-shop-almagest-heritage-js": () => import("./../../../src/pages/shop/almagest-heritage.js" /* webpackChunkName: "component---src-pages-shop-almagest-heritage-js" */),
  "component---src-pages-shop-copernican-revolution-js": () => import("./../../../src/pages/shop/copernican-revolution.js" /* webpackChunkName: "component---src-pages-shop-copernican-revolution-js" */),
  "component---src-pages-shop-custom-poster-js": () => import("./../../../src/pages/shop/custom-poster.js" /* webpackChunkName: "component---src-pages-shop-custom-poster-js" */),
  "component---src-pages-shop-dune-js": () => import("./../../../src/pages/shop/dune.js" /* webpackChunkName: "component---src-pages-shop-dune-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shop-newtonian-apple-js": () => import("./../../../src/pages/shop/newtonian-apple.js" /* webpackChunkName: "component---src-pages-shop-newtonian-apple-js" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

