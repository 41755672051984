import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Section from "~common/components/Section"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"
import Cta from "~common/components/Cta"
import useTheme from "~styles/hooks/useTheme"
import NewsletterEmbeddedSignUp from "~newsletter/components/NewsletterEmbeddedSignUp"
import Event from "./components/Event/Event"

const Celebrate = () => {
  const { desert, goal, birth, union } = useStaticQuery(graphql`
    {
      desert: file(
        relativePath: { eq: "celebrate-your-time/almagesto-desert-big.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }

      goal: file(
        relativePath: { eq: "celebrate-your-time/almagesto-goal.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }

      birth: file(
        relativePath: { eq: "celebrate-your-time/almagesto-birth.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }

      union: file(
        relativePath: { eq: "celebrate-your-time/almagesto-union.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  const { theme } = useTheme()

  return (
    <Section
      bg={getImage(desert)}
      sx={{
        background: `linear-gradient(to bottom, ${theme.colors.secondary} 0%, transparent 25%)`,
      }}
    >
      <Section.Header color="primary" logoColor="softened-secondary">
        Celebrate Your Time
      </Section.Header>
      <Section.Body
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          pt: 0,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gridGap: [1, 2],
            opacity: 0.8,
          }}
        >
          <Event image={getImage(goal)} alt="A Goal">
            A Goal
          </Event>
          <Event image={getImage(birth)} alt="A Birthday">
            A Birthday
          </Event>
          <Event image={getImage(union)} alt="An Wedding">
            A Wedding
          </Event>
        </Box>
        <Icon
          name="star"
          color="accent"
          sx={{ width: 32, height: 32, my: [4, 5] }}
        />
        <Typography
          as="h3"
          variant="large"
          sx={{
            mx: [4, 5],
            my: [4],
            fontStyle: "italic",
            maxWidth: ["100%", "75%", "50%"],
          }}
        >
          A goal, a birthday, an anniversary...
        </Typography>
        <Typography
          variant="normal"
          sx={{
            mx: [4, 5],
            my: 4,
            maxWidth: ["100%", "75%", "50%"],
          }}
        >
          Discover how the planets moved during your special time period from
          the unconventional perspective of a Solar System body of your choice.
        </Typography>
        <Typography
          variant="normal"
          sx={{
            mx: [4, 5],
            my: 4,
            maxWidth: ["100%", "75%", "50%"],
          }}
        >
          <Box as="b" sx={{ fontWeight: 700 }}>
            Craft the perfect gift to celebrate something special
          </Box>{" "}
          that happened here on the Earth with a unique astronomical poster
          built from the planetary movements up there in the sky.
        </Typography>
        <Cta
          to="/shop/custom-poster"
          gtmParams={{ event: "configurator_init" }}
          sx={{ my: [5, 6, 7] }}
        >
          Create your Almagesto
        </Cta>
        <NewsletterEmbeddedSignUp sx={{ pt: [4, 5] }} />
      </Section.Body>
      <Section.Footer logo nasaBadge color="primary" />
    </Section>
  )
}

export default Celebrate
