import React from "react"
import Typography from "~common/components/Typography"
import Bullet from "../Bullet"

const Step = ({ children, i, sx, ...rest }) => (
  <Typography
    as="li"
    variant="normal"
    sx={{
      flex: 1,
      color: "secondary",
      textAlign: "center",
      fontWeight: 500,
      ...sx,
    }}
    {...rest}
  >
    <Bullet i={i} />
    {children}
  </Typography>
)

export default Step
