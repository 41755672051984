import React from "react"
import { Box } from "~common/components/Primitives"

const Main = ({ children, sx, ...rest }) => {
  return (
    <Box as="main" sx={{ ...sx }} {...rest}>
      {children}
    </Box>
  )
}

export default Main
