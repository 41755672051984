import React from "react"
import { Link } from "gatsby"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const routes = [
  {
    name: "Shop",
    path: "/shop",
  },
  {
    name: "Blog",
    path: "/blog",
  },
  {
    name: "The Project",
    path: "/project",
  },
  {
    name: "About us",
    path: "/about",
  },
]

const NavbarNav = ({ sx, ...rest }) => {
  return (
    <Box
      as="ul"
      sx={{
        display: ["none", "none", "flex"],
        flexDirection: ["column", "column", "row"],
        ...sx,
      }}
      {...rest}
    >
      {routes.map(({ name, path }, i) => {
        return (
          <Box
            key={i}
            as="li"
            sx={{
              width: [100, 120, 140, 160],
              textAlign: "center",
            }}
          >
            <Link to={path}>
              <Typography
                variant="heading3"
                as="span"
                sx={{
                  color: "secondary",
                  transition: "all 200ms",
                  position: "relative",
                  ":hover": {
                    color: "accent",
                    letterSpacing: "0.1em",
                  },
                }}
              >
                {name}
              </Typography>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}

export default NavbarNav
