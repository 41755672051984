import React, { useEffect, useState } from "react"
import { format, addDays } from "date-fns"
import { useMotionValue, useSpring } from "framer-motion"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const AnimationBox = ({ animation: Animation, sx, ...rest }) => {
  const time = useSpring(useMotionValue(0), {
    mass: 500,
    stiffness: 2,
    damping: 0,
  })

  const [date, setDate] = useState(new Date(2020, 0, 1))

  useEffect(() => {
    time.set(1)
    return time.onChange(value => {
      const days = value * 365 * 10
      setDate(addDays(new Date(2020, 0, 1), days))
    })
  }, [time])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 5,
        ...sx,
      }}
      {...rest}
    >
      <Animation width="100%" height="100%" time={time} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="small"
          color="primary"
          textAlign="center"
          sx={{
            minWidth: "48px",
            mx: 3,
            letterSpacing: 2,
            fontWeight: 300,
          }}
        >
          {format(date, "dd")}
        </Typography>
        <Typography
          variant="small"
          color="primary"
          textAlign="center"
          sx={{
            minWidth: "48px",
            mx: 3,
            letterSpacing: 2,
            fontWeight: 300,
          }}
        >
          {format(date, "MMM")}
        </Typography>
        <Typography
          variant="small"
          color="primary"
          textAlign="center"
          sx={{
            minWidth: "48px",
            mx: 3,
            letterSpacing: 2,
            fontWeight: 300,
          }}
        >
          {format(date, "yyyy")}
        </Typography>
      </Box>
    </Box>
  )
}

export default AnimationBox
