import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"

const Logos = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: ["space-between", "space-around"],
        p: 5,
        maxWidth: 640,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: "33%" }}>
          <StaticImage src="./astropy.png" alt="astropy" />
        </Box>
        <Box sx={{ width: "33%" }}>
          <StaticImage src="./jpl.png" alt="jpl" />
        </Box>
      </Box>
    </Box>
  )
}

export default Logos
