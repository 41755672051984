import React from "react"
import { useViewportScroll } from "framer-motion"
import useResizeObserver from "use-resize-observer"
import LayoutContext from "./context"

export const LayoutContextProvider = ({ children }) => {
  const { ref: headerRef, height: headerHeight = 0 } = useResizeObserver()

  const { scrollY } = useViewportScroll()

  const [headerCollapse, setHeaderCollapse] = React.useState(false)

  React.useEffect(
    () => scrollY.onChange(value => setHeaderCollapse(value > 100)),
    [scrollY, setHeaderCollapse]
  )

  const [availableHeight, setAvailableHeight] = React.useState(0)

  React.useEffect(() => {
    const handleMeasure = () => {
      setAvailableHeight(window.innerHeight - headerHeight)
    }
    handleMeasure()
    window.addEventListener("resize", handleMeasure)
    return () => window.removeEventListener("resize", handleMeasure)
  }, [headerHeight, setAvailableHeight])

  return (
    <LayoutContext.Provider
      value={{ headerRef, headerCollapse, headerHeight, availableHeight }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export const provideLayoutContext = Component => props =>
  (
    <LayoutContextProvider>
      <Component {...props} />
    </LayoutContextProvider>
  )
