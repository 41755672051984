import { createContext, useContext } from "react"

export const LayoutContext = createContext()

export const useLayoutContext = () => {
  const ctx = useContext(LayoutContext)
  return ctx
}

export default LayoutContext
