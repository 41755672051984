import React from "react"
import { Box } from "~common/components/Primitives"
import CookiesConsent from "~landing/components/CookiesConsent"
import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children, header = false, footer = false }) => {
  return (
    <>
      {header && <Header />}
      <CookiesConsent />
      <Box
        as="main"
        sx={{
          background: ["none", `url("/almagesto-geometric-background.svg")`],
          backgroundPosition: "25% 33%",
          backgroundSize: "100%",
          backgroundRepeat: "repeat",
        }}
      >
        {children}
      </Box>
      {footer && <Footer />}
    </>
  )
}

export default Layout
