import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section from "~common/components/Section";
import Logo from "~common/components/Logo";
import Logos from "./components/Logos";
import WorkInProgress from "./components/WorkInProgress";
import Details from "./components/Details";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Section bg="secondary" color="primary">
    <Section.Header color="primary">Science meets Design</Section.Header>
    <Section.Body sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    pt: [4, 5],
    pb: 0
  }}>
      {children}
    </Section.Body>
    <Section.Footer logo color="primary" />
  </Section>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`We pursue the goal of creating a unique design product without sacrificing scientific accuracy.`}</p>
    <p>{`Positions data are in fact derived from the ephemerides built and published by the `}<strong parentName="p">{`Jet Propulsion Laboratory`}</strong>{` at NASA that guarantees extreme accuracy for the time interval between 1600 AD and 2100 AD. Furthermore, our software can extrapolate data outside this range with the help of `}<strong parentName="p">{`AstroPy`}</strong>{`, the state-of-the-art software library commonly used in the Astrophysics community, to give you the freedom of exploring the solar system for any time span.`}</p>
    <Logos mdxType="Logos" />
    <p>{`Even with the best sources for astronomical data realizing a readable, understandable, communicative and beautiful print was a difficult task.`}</p>
    <WorkInProgress mdxType="WorkInProgress" />
    <p>{`To overcome all the challenges, such as the coexistence of very different scales or the intricacy of the trajectories, we have developed algorithms and graphical themes which ensure an optimal and beautiful product for the full spectrum of cases, even in printing process.`}</p>
    <Details mdxType="Details" />
    <Logo color="softened-secondary" sx={{
      width: ["128vw", "42vw"],
      height: ["128vw", "42vw"],
      position: "absolute",
      left: 0,
      top: 128,
      transform: "translateX(-50%)",
      opacity: 0.1
    }} mdxType="Logo" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      