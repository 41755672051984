const theme = {
  heading1: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: [5, 8, 9, 10],
    fontWeight: 400,
    lineHeight: 2,
    letterSpacing: "0.05em",
    color: "inherit",
  },
  heading2: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: [4, 5, 6, 7],
    fontWeight: 400,
    lineHeight: 2,
    letterSpacing: "0.05em",
    color: "inherit",
  },
  heading3: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: 3,
    fontWeight: 400,
    lineHeight: 2,
    letterSpacing: "0.05em",
    color: "inherit",
  },
  heading4: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: 2,
    fontWeight: 400,
    lineHeight: 2,
    letterSpacing: "0.05em",
    color: "inherit",
  },
  capitalized1: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: [4, 5, 6, 7],
    fontWeight: 500,
    lineHeight: 0,
    color: "inherit",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
  },
  capitalized2: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: [5, 4, 5, 6],
    fontWeight: 500,
    lineHeight: [0],
    color: "inherit",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
  },
  capitalized3: {
    fontFamily: "heading",
    position: "relative",
    top: "2px",
    fontSize: [1],
    fontWeight: 500,
    lineHeight: [0],
    color: "inherit",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
  },
  large: {
    fontFamily: "body",
    fontSize: [4, 4, 5],
    fontWeight: 400,
    lineHeight: 2,
    color: "inherit",
  },
  normal: {
    fontFamily: "body",
    fontSize: 3,
    fontWeight: 400,
    lineHeight: 2,
    color: "inherit",
  },
  small: {
    fontFamily: "body",
    fontSize: [1],
    fontWeight: 400,
    lineHeight: [1],
    color: "inherit",
  },
  tiny: {
    fontFamily: "body",
    fontSize: [0],
    fontWeight: 400,
    lineHeight: 0,
    color: "inherit",
  },
}

export default theme
