import React from "react"
import { alpha } from "@theme-ui/color"
import { Box } from "~common/components/Primitives"

export default React.forwardRef(
  (
    {
      color = "secondary",
      direction = "horizontal",
      align = "center",
      sx,
      ...rest
    },
    ref
  ) => (
    <Box
      ref={ref}
      sx={{
        width: direction === "vertical" ? "1px" : "100%",
        height: direction === "vertical" ? "100%" : "1px",
        background: theme => `linear-gradient(to ${
          direction === "vertical" ? "bottom" : "right"
        },
            ${alpha(color, align === "start" ? 1 : 0)(theme)},
            ${alpha(color, 1)(theme)},
            ${alpha(color, align === "end" ? 1 : 0)(theme)})`,
        ...sx,
      }}
      {...rest}
    />
  )
)
