import { pushToGtm } from "~common/utils/gtm"

const snipcartItemToGA4Product = item => ({
  item_name: item.name,
  item_id: item.id,
  price: item.price,
})

const onItemAdded = item => {
  pushToGtm({ ecommerce: null })
  pushToGtm({
    event: "add_to_cart",
    ecommerce: {
      items: [snipcartItemToGA4Product(item)],
    },
  })
}

const onItemRemoved = item => {
  pushToGtm({ ecommerce: null })
  pushToGtm({
    event: "remove_from_cart",
    ecommerce: {
      items: [snipcartItemToGA4Product(item)],
    },
  })
}

const onCartConfirmed = cart => {
  pushToGtm({ ecommerce: null })
  pushToGtm({
    event: "purchase",
    ecommerce: {
      transaction_id: cart.token,
      value: cart.total,
      currency: cart.currency,
      items: cart.items.items.map(snipcartItemToGA4Product),
    },
  })
}

const subscribeToSnipcartEvents = () => {
  window.Snipcart.events.on("item.added", onItemAdded)
  window.Snipcart.events.on("item.removed", onItemRemoved)
  window.Snipcart.events.on("cart.confirmed", onCartConfirmed)
}

if (typeof window !== "undefined") {
  try {
    subscribeToSnipcartEvents()
  } catch {
    document.addEventListener("snipcart.ready", function () {
      subscribeToSnipcartEvents()
    })
  }
}
