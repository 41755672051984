import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useImage from "~common/hooks/useImage"
import { Box } from "~common/components/Primitives"

const PaymentMethods = ({ variant, sx, ...rest }) => {
  const image = useImage(`payment-methods/payment-methods-${variant}.png`)
  return (
    <Box sx={{ ...sx }} {...rest}>
      <GatsbyImage
        image={getImage(image)}
        alt="payment methods paypal stripe visa mastercard"
      />
    </Box>
  )
}

export default PaymentMethods
