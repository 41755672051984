import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Parallax from "~common/components/Parallax"

const WorkInProgress = () => {
  const { workInProgress } = useStaticQuery(graphql`
    {
      workInProgress: file(relativePath: { eq: "work-in-progress.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <Parallax
      bgImage={getImage(workInProgress)}
      bgImageAlt="almagesto poster making"
      visible={0.8}
      sx={{
        width: "100%",
        my: 5,
      }}
    />
  )
}

export default WorkInProgress
