import React from "react"
import { Link } from "gatsby"
import { useDrawerContext } from "~common/contexts/drawer"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Shop",
    path: "/shop",
  },
  {
    name: "Blog",
    path: "/blog",
  },
  {
    name: "The project",
    path: "/project",
  },
  {
    name: "About us",
    path: "/about",
  },
  {
    name: "Credits",
    path: "/credits",
  },
]

const DrawerNav = ({ sx, ...rest }) => {
  const { handleDrawerClose } = useDrawerContext()

  return (
    <Box
      as="ul"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        ...sx,
      }}
      {...rest}
    >
      {routes.map(({ name, path }, i) => {
        return (
          <Box
            key={i}
            as="li"
            sx={{
              m: 3,
              textAlign: "right",
            }}
          >
            <Link to={path} onClick={handleDrawerClose}>
              <Typography
                variant="heading3"
                as="span"
                sx={{
                  color: "secondary",
                  letterSpacing: "0.2em",
                  textTransform: "uppercase",
                  transition: "all 200ms",
                  position: "relative",
                  ":hover": {
                    color: "accent",
                    letterSpacing: "0.1em",
                  },
                }}
              >
                {name}
              </Typography>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}

export default DrawerNav
