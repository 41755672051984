import React from "react"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"

const Testimonial = ({ children, sx, ...rest }) => {
  return (
    <Box
      sx={{
        p: [4, 4, 4, 5],
        bg: "primary",
        // bg: theme => `${theme.colors["primary"]}ff`,
        // backdropFilter: "blur(3px)",
        maxWidth: [640],
        borderTop: [
          "none",
          theme => `1px dashed ${theme.colors["softened-accent"]}`,
          theme => `1px dashed ${theme.colors["accent"]}`,
          theme => `1px solid ${theme.colors["accent"]}`,
        ],
        boxShadow: [
          "none",
          "none",
          "none",
          theme => `0px 0px 32px ${theme.colors["softened-secondary"]}`,
        ],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const Quote = ({ sx, ...rest }) => (
  <Icon
    name="quotation-mark"
    color="accent"
    sx={{
      width: [16, 20, 24, 32],
      height: [16, 20, 24, 32],
      // opacity: 0.5,
      ...sx,
    }}
    {...rest}
  />
)

Testimonial.Text = ({ children, sx, ...rest }) => (
  <Box
    as="blockquote"
    sx={{
      position: "relative",
      display: "flex",
      flexDirection: "column",
      ...sx,
    }}
    {...rest}
  >
    <Quote
      sx={{
        alignSelf: "flex-start",
        transform: "scale(-1)",
        transformOrigin: "50% 50%",
      }}
    />
    <Typography
      as="p"
      variant="normal"
      sx={{
        py: [2, 3],
        color: "secondary",
        textAlign: "justify",
        fontStyle: "italic",
        zIndex: 1,
      }}
      {...rest}
    >
      {children}
    </Typography>
    <Quote sx={{ alignSelf: "flex-end" }} />
  </Box>
)

Testimonial.Author = ({ children, sx, ...rest }) => (
  <Typography
    variant="normal"
    sx={{
      mt: [4, 5],
      // mr: [2, 3],
      textAlign: "right",
      color: "secondary",
      fontWeight: 500,
      ...sx,
    }}
    {...rest}
  >
    — {children}
  </Typography>
)

export default Testimonial
