import React from "react"
import { AnimatePresence } from "framer-motion"
import { RemoveScroll } from "react-remove-scroll"
import { useLayoutContext } from "~layout/contexts/layout"
import { useDrawerContext } from "~common/contexts/drawer"
import useMedia from "~common/hooks/useMedia"
import { Box } from "~common/components/Primitives"
import Socials from "~common/components/Socials"
import Line from "~common/components/Line"
import Logo from "~common/components/Logo"
import DrawerNav from "./components/DrawerNav"

const Drawer = () => {
  const isAtLeastMd = useMedia("md")

  const { headerHeight, availableHeight } = useLayoutContext()

  const { isDrawerOpen } = useDrawerContext()

  return (
    <AnimatePresence>
      {isDrawerOpen && !isAtLeastMd && (
        <RemoveScroll
          enabled={isDrawerOpen}
          removeScrollBar={true}
          forwardProps
        >
          <Box
            framer
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { type: "tween", duration: 0.3 },
            }}
            exit={{ opacity: 0 }}
            sx={{
              bg: "primary",
              position: "fixed",
              top: headerHeight,
              left: 0,
              right: 0,
              minHeight: availableHeight,
              p: [4, 5],
              display: "grid",
              gridTemplateColumns: "1fr auto",
              gridTemplateRows: "1fr 1fr",
              gridTemplateAreas: '"nav line" ". social"',
              zIndex: 11,
              overflow: "hidden",
            }}
          >
            <Line
              direction="vertical"
              framer
              initial={{ height: 0 }}
              animate={{ height: "100%" }}
              transition={{ type: "tween", duration: 0.3 }}
              sx={{ gridArea: "line", justifySelf: "center" }}
            />
            <DrawerNav sx={{ gridArea: "nav" }} />
            <Logo
              color="softened-accent"
              framer
              initial="initial"
              animate={isDrawerOpen ? "rest" : "initial"}
              variants={{
                initial: { rotate: "90deg", opacity: 0 },
                rest: { rotate: "0deg", opacity: 0.2 },
              }}
              transition={{
                type: "spring",
                mass: 10,
                damping: 50,
              }}
              sx={{
                position: "absolute",
                top: "25%",
                left: "-25%",
                width: "100vw",
                height: "100vh",
                zIndex: -1,
              }}
            />
            <Socials
              color="secondary"
              framer
              initial="initial"
              animate={isDrawerOpen ? "rest" : "initial"}
              variants={{ initial: { y: 100 }, rest: { y: 0 } }}
              transition={{
                type: "tween",
                duration: 0.3,
              }}
              sx={{
                gridArea: "social",
                p: 0,
                mx: 0,
                my: 5,
                justifySelf: "center",
                alignSelf: "start",
                flexDirection: "column",
              }}
              itemProps={{
                sx: { mx: 0, p: 0 },
              }}
            />
          </Box>
        </RemoveScroll>
      )}
    </AnimatePresence>
  )
}

export default Drawer
