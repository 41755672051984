import React from "react"
import useTheme from "~styles/hooks/useTheme"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const RadioButton = ({ selected, onClick, children, sx, ...rest }) => {
  const { theme } = useTheme()

  return (
    <Box
      as="button"
      onClick={onClick}
      framer
      variants={{
        initial: {
          opacity: 0,
          x: -100,
          outline: `1px dashed transparent`,
        },
        rest: {
          opacity: 1,
          x: 0,
          outline: `1px dashed transparent`,
          transition: {
            bounce: 0,
          },
        },
        selected: {
          opacity: 1,
          x: 0,
          outline: `1px dashed transparent`,
          transition: {
            bounce: 0,
          },
        },
        hover: {
          opacity: 1,
          outline: `1px dashed ${theme.colors["softened-secondary"]}`,
          transition: {
            bounce: 0,
          },
        },
        hoverSelected: {
          opacity: 1,
          outline: `1px dashed ${theme.colors["softened-secondary"]}`,
          transition: {
            bounce: 0,
          },
        },
      }}
      initial="initial"
      animate={selected ? "selected" : "rest"}
      whileHover={selected ? "hoverSelected" : "hover"}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

RadioButton.Bullet = function RadioButtonBullet({ sx, ...rest }) {
  const { theme } = useTheme()

  return (
    <Box
      as="span"
      framer
      variants={{
        rest: {
          scale: 1,
          background: theme.colors["softened-secondary"],
        },
        selected: {
          scale: 1.6,
          background: theme.colors.accent,
        },
        hover: {
          scale: 1,
          background: theme.colors.secondary,
        },
        hoverSelected: {
          scale: 1.6,
          background: theme.colors.accent,
        },
      }}
      sx={{
        m: [2, 3],
        width: 3,
        height: 3,
        borderRadius: "50%",
        ...sx,
      }}
      {...rest}
    />
  )
}

RadioButton.Text = function RadioButtonText({ children, sx, ...rest }) {
  const { theme } = useTheme()
  return (
    <Typography
      variant="small"
      framer
      variants={{
        rest: {
          fontWeight: 400,
          color: theme.colors["softened-secondary"],
          transition: {
            bounce: 0,
          },
        },
        selected: {
          fontWeight: 700,
          color: theme.colors.accent,
        },
        hover: {
          fontWeight: 500,
          color: theme.colors.secondary,
          transition: {
            bounce: 0,
          },
        },
        hoverSelected: {
          fontWeight: 700,
          color: theme.colors.accent,
          transition: {
            bounce: 0,
          },
        },
      }}
      sx={{
        mr: [2, 3],
        flex: 1,
        textAlign: "center",
        letterSpacing: "0.1em",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default RadioButton
