import React from "react"
import { useLocation } from "@reach/router"
import { useViewportScroll } from "framer-motion"
import useMedia from "~common/hooks/useMedia"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Socials from "~common/components/Socials"
import Line from "~common/components/Line"

const SideBar = ({
  color = "accent",
  onHoverColor = "softened-secondary",
  sx,
  ...rest
}) => {
  const isAtLeastMd = useMedia("md")

  const { scrollY } = useViewportScroll()

  const [isVisible, setIsVisible] = React.useState(false)

  const location = useLocation()

  React.useEffect(() => {
    setIsVisible(location.pathname === "/")
  }, [location, setIsVisible])

  React.useEffect(() => {
    return scrollY.onChange(value => {
      const progress = value / (document.body.clientHeight - window.innerHeight)
      const remaining = document.body.clientHeight - window.innerHeight - value
      setIsVisible(progress < 0.9 && remaining > window.innerHeight)
    })
  }, [scrollY, setIsVisible])

  return isAtLeastMd ? (
    <Box
      as="aside"
      sx={{
        width: 24,
        position: "fixed",
        bottom: 40,
        right: [4, 5],
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        zIndex: 11,
        transition: "all 500ms",
        opacity: isVisible ? 1 : 0,
        "@media screen and (max-height: 700px)": {
          display: "none",
        },
        visibility: isVisible ? "visible" : "hidden",
        ...sx,
      }}
      {...rest}
    >
      <Line direction="vertical" color="accent" sx={{ height: "5vh", my: 5 }} />
      <Socials
        color={color}
        onHoverColor={onHoverColor}
        sx={{ flexDirection: "column" }}
      />
      <Typography
        variant="small"
        color={color}
        sx={{
          display: "block",
          letterSpacing: "0.2em",
          verticalAlign: "middle",
          textTransform: "uppercase",
          userSelect: "none",
          pointerEvents: "none",
          position: "relative",
          top: 6,
          left: -1,
          transformOrigin: "center",
          transform: "translateX(-1px)rotate(90deg)",
          width: 128,
          height: 24,
          mb: 104,
          "@media screen and (max-height: 450px)": {
            display: "none",
          },
        }}
      >
        Follow us
      </Typography>
      <Line direction="vertical" color="accent" sx={{ height: "5vh", my: 5 }} />
    </Box>
  ) : null
}

export default SideBar
