import React from "react"
import { Link } from "gatsby"
import { Box } from "~common/components/Primitives"
import Brand from "~common/components/Brand"
import Typography from "~common/components/Typography"
import Socials from "~common/components/Socials"
import Line from "~common/components/Line"
import PaymentMethods from "~shop/components/PaymentMethods"

const Footer = () => {
  return (
    <Box
      id="id-footer"
      as="footer"
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
        gridTemplateAreas: [
          '"logos" "contacts" "links" "brand" "socials" "copyright"',
          '"contacts logos socials" "links brand ." "links brand ." "copyright copyright copyright"',
        ],
        bg: "secondary",
        position: "relative",
        minHeight: 8,
        zIndex: 10,
        p: [4, 5],
        gridGap: [4, 5],
      }}
    >
      <PaymentMethods
        variant="footer"
        sx={{
          gridArea: "logos",
          justifySelf: "center",
          alignSelf: "start",
        }}
      />
      <Box sx={{ gridArea: "contacts" }}>
        <Typography
          variant="normal"
          sx={{
            fontWeight: 500,
            color: "primary",
            textAlign: ["center", "left"],
          }}
        >
          Contacts
        </Typography>
        <Typography
          variant="small"
          sx={{ color: "primary", textAlign: ["center", "left"] }}
        >
          info@almagesto.xyz
        </Typography>
      </Box>
      <Box
        as="ul"
        sx={{
          gridArea: "links",
          mt: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: ["center", "flex-start"],
        }}
      >
        {[
          // { to: "/press-kit", text: "Press Kit" },
          // { to: "/affiliate", text: "Affiliate Program" },
          { to: "/credits", text: "Credits" },
          { to: "/privacy", text: "Privacy Policy" },
          { to: "/terms", text: "General Sale Terms" },
          { to: "/payment", text: "Payment Methods" },
          { to: "/refunds", text: "Withdrawals & Refunds" },
          { to: "/faq", text: "Q&A" },
        ].map(({ to, text }, i) => (
          <Typography
            key={i}
            as="li"
            variant="small"
            sx={{
              my: [2, 3],
              a: {
                py: [2, 3],
                cursor: "pointer",
                color: "primary",
                "&:hover": { color: "accent" },
                display: "flex",
                alignItems: "center",
                border: [
                  theme => `1px dashed ${theme.colors["softened-secondary"]}`,
                  "none",
                ],
                borderRadius: 4,
                "&:before, &:after": {
                  content: '"•"',
                  display: ["auto", "none"],
                  mx: [3, 4],
                },
              },
            }}
          >
            <Link to={to}>{text}</Link>
          </Typography>
        ))}
        <Line
          color="softened-secondary"
          align="start"
          sx={{ display: ["none", "auto"], mt: 3 }}
        />
      </Box>
      <Brand
        color="primary"
        vertical
        sx={{
          gridArea: "brand",
          placeSelf: "center",
          p: [4, 5],
        }}
      />
      <Socials
        color="primary"
        sx={{
          gridArea: "socials",
          justifySelf: ["center", "end"],
          alignSelf: "start",
        }}
      />
      <Typography
        variant="small"
        sx={{
          gridArea: "copyright",
          alignSelf: ["end"],
          color: "softened-secondary",
          textAlign: ["center", "right"],
        }}
      >
        Copyright © 2020 Almagesto &#183; All Rights Reserved
      </Typography>
    </Box>
  )
}

export default Footer
