import React from "react"
import useMedia from "~common/hooks/useMedia"
import DrawerContext from "./context"

export const DrawerContextProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const handleDrawerOpen = React.useCallback(() => setIsDrawerOpen(true), [])
  const handleDrawerClose = React.useCallback(() => setIsDrawerOpen(false), [])

  const isAtLeastMd = useMedia("md")

  React.useEffect(() => {
    const handleResize = () => isAtLeastMd && handleDrawerClose()
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleDrawerClose, isAtLeastMd])

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        setIsDrawerOpen,
        handleDrawerOpen,
        handleDrawerClose,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export const provideDrawerContext = Component => props =>
  (
    <DrawerContextProvider>
      <Component {...props} />
    </DrawerContextProvider>
  )

export default DrawerContextProvider
