import React from "react"
import { Box, Image } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Logo from "~common/components/Logo"
// import Icon from "~common/components/Icon"

const Plus = ({ children, sx, ...rest }) => (
  <Box
    sx={{
      width: "100%",
      aspectRatio: "1",
      borderRadius: "50%",
      border: theme => `1px dashed ${theme.colors["accent"]}`,
      boxShadow: theme => `0px 0px 32px ${theme.colors["softened-secondary"]}`,
      position: "relative",
      ...sx,
    }}
    {...rest}
  >
    <Logo
      color="softened-secondary"
      sx={{
        position: "absolute",
        top: "10%",
        left: "10%",
        width: "80%",
        height: "80%",
        opacity: 0.2,
      }}
    />
    <Box
      sx={{
        width: "100%",
        aspectRatio: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Logo color="accent" sx={{ width: 5, height: 5, my: [2, 3] }} />
      {children}
    </Box>
  </Box>
)

Plus.Icon = ({ name }) => (
  <Image
    src={`/specials/${name}.png`}
    alt=""
    width="25%"
    height="25%"
    sx={{}}
  />
  // <Icon name={name} sx={{ width: ["25%"], height: ["25%"] }} />
)

Plus.Title = ({ children }) => (
  <Typography
    as="h2"
    variant="capitalized3"
    sx={{
      mx: ["9%"],
      my: 3,
      color: "accent",
      textAlign: "center",
      shapeOutside: "circle(100% at 50% 50%)",
    }}
  >
    {children}
  </Typography>
)

Plus.Description = ({ children }) => (
  <Typography
    variant="small"
    sx={{
      mx: ["9%"],
      my: 3,
      color: "secondary",
      textAlign: "center",
    }}
  >
    {children}
  </Typography>
)

export default Plus
