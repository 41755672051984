import React from "react"
import { Box } from "~common/components/Primitives"
import Logo from "~common/components/Logo"
import Typography from "~common/components/Typography"

const defaultContainerVariants = {
  initial: {},
  rest: {},
}

const defaultLogoVariants = {
  initial: {
    scale: 0.5,
    rotate: "360deg",
  },
  rest: {
    scale: 1,
    opacity: 1,
    rotate: "0deg",
    transition: {
      scale: {
        type: "tween",
        duration: 2,
      },
      rotate: {
        type: "spring",
        damping: 10,
        stiffness: 3,
      },
    },
  },
}

const defaultLetterVariants = i => ({
  initial: {
    opacity: 0,
  },
  rest: {
    opacity: 1,
    transition: {
      delay: 1 + 0.05 * i,
    },
  },
})

const Brand = ({
  color = "secondary",
  animated = false,
  vertical = false,
  containerVariants = defaultContainerVariants,
  logoVariants = defaultLogoVariants,
  letterVariants = defaultLetterVariants,
  logoProps = {},
  textProps = {},
  letterProps = {},
  sx,
  ...rest
}) => (
  <Box
    vertical={vertical}
    variants={containerVariants}
    initial={animated ? "initial" : "rest"}
    animate="rest"
    sx={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: vertical ? "column" : "row",
      ...sx,
    }}
    {...rest}
  >
    <Logo
      color={color}
      framer
      variants={logoVariants}
      initial={animated ? "initial" : "rest"}
      animate="rest"
      {...logoProps}
    />
    <Typography
      as="h1"
      variant="heading3"
      sx={{
        display: "inline-block",
        ml: vertical ? 0 : 4,
        mt: vertical ? 4 : 0,
        textAlign: vertical ? "center" : "left",
        overflow: "hidden",
        color,
      }}
      {...textProps}
    >
      {Array.from("Almagesto").map((letter, i) => (
        <Box
          framer
          sx={{
            display: "inline",
            overflow: "hidden",
          }}
          as="span"
          key={i}
          variants={letterVariants(i)}
          initial={animated ? "initial" : "rest"}
          animate="rest"
          {...letterProps}
        >
          {letter}
        </Box>
      ))}
    </Typography>
  </Box>
)

export default Brand
