import React from "react"
import { Heading, Text } from "~common/components/Primitives"

const Typography = ({ variant = "normal", children, sx, ...rest }) => {
  switch (variant) {
    case "heading1":
      return (
        <Heading as="h1" variant="heading1" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "heading2":
      return (
        <Heading as="h2" variant="heading2" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "heading3":
      return (
        <Heading as="h3" variant="heading3" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "heading4":
      return (
        <Heading as="h4" variant="heading4" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "capitalized1":
      return (
        <Heading as="h1" variant="capitalized1" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "capitalized2":
      return (
        <Heading as="h2" variant="capitalized2" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "capitalized3":
      return (
        <Heading as="h3" variant="capitalized3" sx={{ ...sx }} {...rest}>
          {children}
        </Heading>
      )
    case "large":
      return (
        <Text as="p" variant="large" sx={{ ...sx }} {...rest}>
          {children}
        </Text>
      )
    case "normal":
      return (
        <Text as="p" variant="normal" sx={{ ...sx }} {...rest}>
          {children}
        </Text>
      )
    case "small":
      return (
        <Text as="p" variant="small" sx={{ ...sx }} {...rest}>
          {children}
        </Text>
      )
    case "tiny":
      return (
        <Text as="p" variant="tiny" sx={{ ...sx }} {...rest}>
          {children}
        </Text>
      )
    default:
      throw new Error(`${variant} not supported`)
  }
}

export default Typography
