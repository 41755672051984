import React from "react"
import { Box } from "~common/components/Primitives"
import Icon from "~common/components/Icon"

const Socials = ({
  color = "softened-secondary",
  onHoverColor = "accent",
  sx,
  itemProps = {},
  ...rest
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...sx,
    }}
    {...rest}
  >
    {[
      { name: "facebook", url: "https://www.facebook.com/AlmagestoDesign" },
      { name: "twitter", url: "https://twitter.com/AlmagestoDesign" },
      { name: "instagram", url: "http://www.instagram.com/almagesto.design" },
    ].map(({ name, url }) => {
      const { sx, ...rest } = itemProps
      return (
        <Box
          key={name}
          sx={{
            borderRadius: "50%",
            m: 3,
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            ...sx,
          }}
          {...rest}
        >
          <a href={url} target="_blank" rel="noreferrer" aria-label={name}>
            <Icon
              aria-label={name}
              as="button"
              name={name}
              sx={{
                fill: color,
                cursor: "pointer",
                "&:hover": { fill: onHoverColor },
              }}
            />
          </a>
        </Box>
      )
    })}
  </Box>
)

export default Socials
