import React from "react"
import { Box as ThemeUiBox } from "theme-ui"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const FramerBox = motion(ThemeUiBox)

const IntersectingBox = React.forwardRef(
  ({ children, variants, intersectingOptions, ...rest }, ref) => {
    const [inViewRef, isInView] = useInView(intersectingOptions)

    const setRefs = React.useCallback(
      node => {
        if (ref) {
          ref.current = node
        }
        inViewRef(node)
      },
      [inViewRef, ref]
    )

    const [variant, setVariant] = React.useState("invisible")

    React.useEffect(() => {
      setVariant(isInView ? "visible" : "invisible")
    }, [isInView, setVariant])

    return React.createElement(
      FramerBox,
      {
        ref: setRefs,
        variants,
        initial: "invisible",
        animate: variant,
        ...rest,
      },
      children
    )
  }
)

export const Box = React.forwardRef(
  (
    { children, framer, intersecting, intersectingOptions, variants, ...rest },
    ref
  ) => {
    if (intersecting) {
      return (
        <IntersectingBox
          ref={ref}
          variants={variants}
          intersectingOptions={intersectingOptions}
          {...rest}
        >
          {children}
        </IntersectingBox>
      )
    } else if (framer) {
      return (
        <FramerBox ref={ref} variants={variants} {...rest}>
          {children}
        </FramerBox>
      )
    } else {
      return (
        <ThemeUiBox ref={ref} {...rest}>
          {children}
        </ThemeUiBox>
      )
    }
  }
)

export const Heading = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="h2"
    variant="heading"
    {...props}
    __themeKey="text"
    __css={{
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    }}
  />
))

export const Text = React.forwardRef((props, ref) => (
  <Box as="span" ref={ref} variant="default" {...props} __themeKey="text" />
))

export const Image = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="img"
    {...props}
    __themeKey="images"
    __css={{
      maxWidth: "100%",
      height: "auto",
      ...props.__css,
    }}
  />
))

export const Button = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="button"
    variant="primary"
    {...props}
    __themeKey="buttons"
    __css={{
      appearance: "none",
      display: props.hidden ? undefined : "inline-block",
      textAlign: "center",
      lineHeight: "inherit",
      textDecoration: "none",
      fontSize: "inherit",
      px: 3,
      py: 2,
      color: "white",
      bg: "primary",
      border: 0,
      borderRadius: 4,
    }}
  />
))

export const Input = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="input"
    variant="input"
    {...props}
    __themeKey="forms"
    __css={{
      display: "block",
      width: "100%",
      p: 2,
      appearance: "none",
      fontSize: "inherit",
      lineHeight: "inherit",
      color: "inherit",
      bg: "transparent",
      border: theme => `1px solid ${theme.colors[props?.color || "secondary"]}`,
      borderRadius: 0,
      "&::placeholder": {
        opacity: 0.8,
        fontSize: "0.8rem",
        color: props?.color || "softened-secondary",
      },
    }}
  />
))

export const Label = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="label"
    variant="label"
    {...props}
    __themeKey="forms"
    __css={{
      fontFamily: "body",
      fontSize: [3],
      color: "inherit",
    }}
  />
))

export const Textarea = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="textarea"
    variant="textarea"
    {...props}
    __themeKey="forms"
    __css={{
      p: 2,
      width: "100%",
      appearance: "none",
      fontSize: "inherit",
      lineHeight: "inherit",
      color: "inherit",
      bg: "transparent",
      border: theme => `1px solid ${theme.colors[props?.color || "secondary"]}`,
      borderRadius: 0,
      "&::placeholder": {
        opacity: 0.8,
        fontSize: "0.8rem",
        color: props?.color || "softened-secondary",
      },
    }}
  />
))
