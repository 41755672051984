import React from "react";
import Layout from "~landing/components/Layout";
import HeroSpaceGift from "~landing/components/HeroSpaceGift";
import HowItWorks from "~landing/components/HowItWorks";
import Mockups from "~landing/components/Mockups";
import Testimonials from "~landing/components/Testimonials";
import WhatMakesItSpecial from "~landing/components/WhatMakesItSpecial";
import Celebrate from "~landing/components/Celebrate";
import ScienceMeetsDesign from "~landing/components/ScienceMeetsDesign";
import AChangeOfPerspective from "~landing/components/AChangeOfPerspective";
export default {
  React,
  Layout,
  HeroSpaceGift,
  HowItWorks,
  Mockups,
  Testimonials,
  WhatMakesItSpecial,
  Celebrate,
  ScienceMeetsDesign,
  AChangeOfPerspective
};