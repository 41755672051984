const React = require(`react`)
const Layout = require(`~layout/components/Layout`).default
const GlobalStyles = require(`~styles/components/GlobalStyles`).default
const Mdx = require(`~common/components/Mdx`).default
require("~common/utils/snipcart")

export const pageWrapper = ({ element, props }) => {
  return (
    <Mdx>
      <GlobalStyles />
      <Layout {...props}>{element}</Layout>
    </Mdx>
  )
}
