import React from "react"
import { Box } from "~common/components/Primitives"
import Section from "~common/components/Section"
import Socials from "~common/components/Socials"
import Cta from "~common/components/Cta"
import Step from "./components/Step"

const HowItWorks = () => {
  return (
    <Section bg="transparent" color="secondary">
      <Section.Header>How it works</Section.Header>
      <Section.Body sx={{ pb: [5, 6] }}>
        <Box
          as="ul"
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "center",
            mx: [4, 4, "8%", "12%"],
            my: 4,
          }}
        >
          <Step i={1} sx={{ mx: [2, 3, 4, 5], my: [4, 5] }}>
            Choose the reference planet, i.e.{" "}
            <Box as="span" sx={{ fontStyle: "italic" }}>
              your point of view
            </Box>
            . Then, choose your meaningful period by setting the starting and
            ending dates.
          </Step>
          <Step i={2} sx={{ mx: [2, 3, 4, 5], my: [4, 5] }}>
            Let Almagesto calculate the planets' orbits and preview them in
            seconds.
          </Step>
          <Step i={3} sx={{ mx: [2, 3, 4, 5], my: [4, 5] }}>
            Customize the final design by choosing the zoom level and the color
            palette. Optionally add a personal message.
          </Step>
        </Box>
        <Box sx={{ my: 5, display: "flex", justifyContent: "center" }}>
          <Cta
            to="/shop/custom-poster"
            gtmParams={{ event: "configurator_init" }}
          >
            Design your Almagesto
          </Cta>
        </Box>
        <Socials color="secondary" sx={{ my: 4 }} />
      </Section.Body>
      <Section.Footer logo color="secondary" />
    </Section>
  )
}

export default HowItWorks
