import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"

const Image = ({ image, alt, description }) => (
  <Box sx={{ maxWidth: [640, 680, 720, 780], mx: "auto", my: [3, 4] }}>
    <GatsbyImage alt={alt} image={image} />
    {description !== undefined && (
      <Box as="figcaption" sx={{ my: [2, 3] }}>
        {description || alt}
      </Box>
    )}
  </Box>
)

export default Image
