import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Section from "~common/components/Section"
import Typography from "~common/components/Typography"
import Line from "~common/components/Line"

const Mockups = () => {
  const { mockup1, mockup2, mockup3 } = useStaticQuery(graphql`
    {
      mockup1: file(
        relativePath: { eq: "almagesto-astronomy-print-poster-art-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            breakpoints: [256, 512]
            quality: 90
          )
        }
      }
      mockup2: file(
        relativePath: { eq: "almagesto-astronomy-print-poster-art-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            breakpoints: [256, 512]
            quality: 90
          )
        }
      }
      mockup3: file(
        relativePath: { eq: "almagesto-astronomy-print-poster-art-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            breakpoints: [256, 512]
            quality: 90
          )
        }
      }
    }
  `)
  return (
    <Section bg="transparent" color="secondary">
      <Section.Header bg="transparent" color="accent">
        A unique design poster
      </Section.Header>
      <Section.Body sx={{ pb: 0 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: ["repeat(3, 1fr)"],
            gridGap: [1, 2],
            bg: "primary",
          }}
        >
          <GatsbyImage
            image={getImage(mockup1)}
            alt="almagesto astronomy print poster art"
            sizes="33vw"
          />
          <GatsbyImage
            image={getImage(mockup2)}
            alt="almagesto astronomy print poster art"
            sizes="33vw"
          />
          <GatsbyImage
            image={getImage(mockup3)}
            alt="almagesto astronomy print poster art"
            sizes="33vw"
          />
        </Box>
        <Typography
          as="p"
          variant="small"
          sx={{
            mx: "auto",
            my: [4, 5],
            px: [4, 5],
            width: ["100%", "75%", "50%"],
            maxWidth: 640,
            textAlign: "center",
          }}
        >
          Each Almagesto is a one-of-a-kind design poster, shaped by your
          personal choices of reference planet, starting and ending dates, zoom
          level, and color palette.
        </Typography>
        <Line
          color="secondary"
          align="center"
          sx={{ mx: "auto", my: [4, 5], width: ["75%", "50%", "33%"] }}
        />
      </Section.Body>
    </Section>
  )
}

export default Mockups
