import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  provideProductContext,
  useProductContext,
} from "~shop/contexts/ProductContext"
import { Box } from "~common/components/Primitives"
import SchemaMarkup from "~common/components/SchemaMarkup"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"
import Button from "~common/components/Button"
import RadioButton from "~common/components/RadioButton"
import PriceTag from "~shop/components/PriceTag"
import Cta from "~common/components/Cta"

const ProductDetails = provideProductContext(({ children, sx, ...rest }) => {
  const { product } = useProductContext()
  const { name, image, description, price } = product
  return (
    <Box
      sx={{
        px: [0, 0, "9%", "16%"],
        py: [4, 5, 6],
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr", "repeat(2, 1fr)"],
        gridTemplateAreas: [
          '"heading" "gallery" "info"',
          '"heading" "gallery" "info"',
          '"gallery info"',
        ],
        ...sx,
      }}
      {...rest}
    >
      <SchemaMarkup
        data={{
          "@context": "https://schema.org/",
          "@type": "Product",
          name,
          image: image,
          description,
          brand: "Almagesto",
          offers: {
            "@type": "Offer",
            url: "/custom-poster",
            priceCurrency: "EUR",
            price,
            availability: "https://schema.org/InStock",
          },
        }}
      />
      {children}
    </Box>
  )
})

ProductDetails.Gallery = function ProductDetailsGallery({
  children,
  sx,
  ...rest
}) {
  return (
    <Box sx={{ gridArea: "gallery", ...sx }} {...rest}>
      {children}
    </Box>
  )
}

ProductDetails.Image = function ProductDetailsImage({ sx, ...rest }) {
  const { product, selectedImage } = useProductContext()

  return (
    <GatsbyImage
      image={selectedImage}
      alt={product?.description}
      objectFit="cover"
      {...rest}
    />
  )
}

ProductDetails.ImageSelector = function ProductDetailsImageSelector({
  sx,
  ...rest
}) {
  const { product, selectedImageIndex, setSelectedImageIndex } =
    useProductContext()
  const { gallery } = product

  return (
    <Box
      sx={{
        py: [3, 4],
        px: [4, 5, 0],
        display: "flex",
        justifyContent: "flex-start",
        "button + button": { ml: [3, 4] },
        ...sx,
      }}
      {...rest}
    >
      {gallery.map((image, i) => (
        <Box
          key={i}
          as="button"
          onClick={() => setSelectedImageIndex(i)}
          sx={{
            width: [64, 64, 80],
            aspectRatio: 1,
            opacity: selectedImageIndex === i ? 1 : 0.5,
            cursor: "pointer",
            border: theme =>
              `1px solid ${
                theme.colors[
                  selectedImageIndex === i
                    ? "softened-accent"
                    : "softened-secondary"
                ]
              }`,
          }}
        >
          <GatsbyImage image={image} alt={product?.description} {...rest} />
        </Box>
      ))}
    </Box>
  )
}

ProductDetails.Heading = function ProductDetailsHeading({
  children,
  sx,
  ...rest
}) {
  return (
    <Box
      sx={{
        display: ["block", "block", "none"],
        gridArea: "heading",
        px: [4, 5],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

ProductDetails.Title = function ProductDetailsTitle({ sx, ...rest }) {
  const { product } = useProductContext()

  return (
    <Typography
      as="h1"
      variant="capitalized2"
      sx={{
        color: "secondary",
        lineHeight: [1],
        ...sx,
      }}
      {...rest}
    >
      {product?.title || product.name}
    </Typography>
  )
}

ProductDetails.Info = function ProductDetailsInfo({ children, sx, ...rest }) {
  return (
    <Box
      sx={{
        gridArea: "info",
        maxWidth: ["100%", "100%", 640],
        mx: [4, 5],
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

ProductDetails.Description = function ProductDetailsDescription({
  children,
  sx,
  ...rest
}) {
  const { product } = useProductContext()

  return (
    <Typography
      variant="small"
      sx={{
        fontWeight: 500,
        lineHeight: [1],
        ...sx,
      }}
      {...rest}
    >
      {children || product?.description}
    </Typography>
  )
}

ProductDetails.PriceTag = function ProductDetailsPriceTag({
  cart = true,
  sx,
  ...rest
}) {
  const { product } = useProductContext()
  const { price, discount } = product

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }} {...rest}>
      {cart && <Icon name="cart" color="accent" />}
      <PriceTag
        price={price}
        discount={discount}
        sx={{ ml: cart ? [4, 5, 6] : 0 }}
      />
    </Box>
  )
}

ProductDetails.Feature = ({ children, icon, sx, ...rest }) => (
  <Box as="li" sx={{ display: "flex", alignItems: "center", ...sx }} {...rest}>
    <Icon name={icon} color="secondary" sx={{ mr: [3, 3, 4] }} />
    <Typography variant="small" sx={{ color: "secondary" }}>
      {children}
    </Typography>
  </Box>
)

ProductDetails.Note = ({ children, sx, ...rest }) => (
  <Box as="li" sx={{ display: "flex", alignItems: "center", ...sx }} {...rest}>
    <Typography variant="small" sx={{ color: "softened-secondary" }}>
      <Box as="span" sx={{ mr: [3, 4] }}>
        *
      </Box>
      {children}
    </Typography>
  </Box>
)

ProductDetails.Cta = function ProductDetailsCta({
  children,
  gtmParams,
  sx,
  ...rest
}) {
  const { product } = useProductContext()

  if (!product) return null

  const { id, name, description, image, url, price, customizable } = product

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        ...sx,
      }}
      {...rest}
    >
      {customizable ? (
        <Cta
          to={`/configurator?productId=${id}`}
          gtmParams={{ item_id: id, ...gtmParams }}
        >
          {children || "Create Your Almagesto"}
        </Cta>
      ) : (
        <Button
          variant="accent"
          className="snipcart-add-item"
          data-item-id={id}
          data-item-price={price}
          data-item-url={url}
          data-item-description={description}
          data-item-image={image}
          data-item-name={name}
        >
          {children || "Add To Cart"}
        </Button>
      )}
    </Box>
  )
}

ProductDetails.Size = function ProductDetailsSize({ sx, ...rest }) {
  const { product } = useProductContext()

  if (!product) return null

  const { size } = product

  return (
    <Box as="span" sx={{ ...sx }} {...rest}>
      {size}
    </Box>
  )
}

ProductDetails.Selector = function ProductDetailsSelector({
  productsMap,
  sx,
  ...rest
}) {
  const { product, selectProductById } = useProductContext()

  if (!product) return null

  const handleSelect = productId => () => selectProductById(productId)

  return (
    <Box sx={{ ...sx }} {...rest}>
      {Object.keys(productsMap).map((productId, i) => {
        const productName = productsMap[productId]
        const selected = product.id === productId

        return (
          <RadioButton
            key={i}
            selected={selected}
            onClick={handleSelect(productId)}
            sx={{
              my: 0,
            }}
          >
            <RadioButton.Bullet />
            <RadioButton.Text sx={{ textAlign: "left" }}>
              {productName.toUpperCase()}
            </RadioButton.Text>
          </RadioButton>
        )
      })}
    </Box>
  )
}

export default ProductDetails
