import React from "react"
import { Box } from "~common/components/Primitives"
import Svg from "./nasa-badge.svg"

const NasaBadge = ({ color, sx, ...rest }) => (
  <Box sx={{ width: [80, 120, 160], fill: color, ...sx }} {...rest}>
    <Svg style={{ width: "100%", height: "100%" }} />
  </Box>
)

export default NasaBadge
