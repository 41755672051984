import React from "react"
import { Box } from "~common/components/Primitives"
import Hero from "~landing/components/Hero"

const HeroSpacePoster = () => (
  <Hero
    renderTitle={() => "A unique piece of astronomical wall art"}
    renderCardTitle={() => "Custom Poster"}
    renderCardDescription1={() =>
      "An accurate astronomical map that displays the trajectories of the Planets as they appear from one of them."
    }
    renderCardDescription2={() => (
      <>
        Customize the{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          reference planet
        </Box>
        , the{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          starting and ending dates
        </Box>
        , the{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          zoom level
        </Box>
        ,{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          color scheme
        </Box>
        , and optionally add your{" "}
        <Box as="span" sx={{ fontWeight: 700 }}>
          personal message
        </Box>
        .
      </>
    )}
    renderCardDescription3={() =>
      "Have you ever wondered how the Solar System appear from Mars? Find it out with the best space poster for the science nerds."
    }
    renderCallToAction={() => "Create your Solar System Map"}
    renderBottomParagraph={() => (
      <>
        Celebrate something special through a{" "}
        <Box as="b" sx={{ fontWeight: 700 }}>
          personalized design poster
        </Box>{" "}
        that unfolds the movements of the Solar System as they appear from one
        of its planets.
      </>
    )}
  />
)

export default HeroSpacePoster
