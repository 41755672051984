import { mix, darken } from "polished"
import text from "./text"
import buttons from "./buttons"

const createTheme = theme => {
  theme.colors["softened-secondary"] = mix(
    0.3,
    theme.colors.secondary,
    theme.colors.primary
  )
  theme.colors["softened-accent"] = mix(
    0.3,
    theme.colors.accent,
    theme.colors.primary
  )
  theme.colors["darkened-secondary"] = darken(0.075, theme.colors.secondary)

  theme.breakpoints.xs = "0px"
  theme.breakpoints.sm = theme.breakpoints[0]
  theme.breakpoints.md = theme.breakpoints[1]
  theme.breakpoints.lg = theme.breakpoints[2]
  theme.breakpoints.xl = theme.breakpoints[3]

  theme.mediaQueries = {
    sm: `@media screen and (min-width: ${theme.breakpoints[0]})`,
    md: `@media screen and (min-width: ${theme.breakpoints[1]})`,
    lg: `@media screen and (min-width: ${theme.breakpoints[2]})`,
    xl: `@media screen and (min-width: ${theme.breakpoints[3]})`,
  }

  return theme
}

export const light = createTheme({
  colors: {
    primary: "#fefefe",
    secondary: "#37515f",
    // accent: "#b88f55",
    accent: "#907044",
    error: "#db504a",
  },
  breakpoints: ["600px", "960px", "1280px", "1920px"],
  fonts: {
    heading: `"Josefin Sans", sans-serif`,
    body: `Quicksand, sans-serif`,
    greek: `Caudex, Quicksand, sans-serif`,
    arabic: `Lateef, Quicksand, sans-serif`,
  },
  fontSizes: [
    "0.6rem",
    "0.8rem",
    "0.9rem",
    "1rem",
    "1.2rem",
    "1.5rem",
    "1.6rem",
    "2rem",
    "2.5rem",
    "3rem",
    "3.6rem",
  ],
  lineHeights: ["1em", "1.25em", "1.5em", "1.75em", "2.0em"],
  space: [
    "0px",
    "2px",
    "4px",
    "8px",
    "16px",
    "32px",
    "64px",
    "128px",
    "256px",
    "512px",
    "1024px",
  ],
  sizes: [
    "0px",
    "2px",
    "4px",
    "8px",
    "16px",
    "32px",
    "64px",
    "128px",
    "256px",
    "512px",
    "1024px",
  ],
  text,
  buttons,
})

export const dark = createTheme({
  ...light,
  colors: {
    primary: "#37515f",
    secondary: "#fefefe",
    accent: "#b88f55",
  },
})

export const arabicAlpha = createTheme({
  ...light,
  colors: {
    primary: "#2a3740",
    secondary: "#ffe17f",
    accent: "#d7885b",
  },
})

export const arabicBeta = createTheme({
  ...light,
  colors: {
    primary: "#125849",
    secondary: "#bdd3bd",
    accent: "#bb8431",
  },
})

export const arabicGamma = createTheme({
  ...light,
  colors: {
    primary: "#bb8431",
    secondary: "#eaeaea",
    accent: "#2a7585",
  },
})
export const greekAlpha = createTheme({
  ...light,
  colors: {
    primary: "#86573b",
    secondary: "#e5e2e4",
    accent: "#231e1f",
  },
})

export const greekBeta = createTheme({
  ...light,
  colors: {
    primary: "#1a1918",
    secondary: "#f58731",
    accent: "#f2f0eb",
  },
})

export const greekGamma = createTheme({
  ...light,
  colors: {
    primary: "#faf4f0",
    secondary: "#924a1b",
    accent: "#d1832d",
  },
})
export const latinAlpha = createTheme({
  ...light,
  colors: {
    primary: "#25252d",
    secondary: "#e7b501",
    accent: "#a93f0e",
  },
})

export const latinBeta = createTheme({
  ...light,
  colors: {
    primary: "#4b1523",
    secondary: "#ffd700",
    accent: "#6bb9f0",
  },
})

export const latinGamma = createTheme({
  ...light,
  colors: {
    primary: "#ad833c",
    secondary: "#0000a5",
    accent: "#4b1523",
  },
})

export default light
