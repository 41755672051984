import React from "react"
import { Box } from "~common/components/Primitives"

const Icon = ({ name, color = "secondary", sx, ...rest }) => {
  const Svg = require(`./icons/${name}.svg`).default

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        fill: color,
        display: "grid",
        placeItems: "center",
        ...sx,
      }}
      {...rest}
    >
      <Svg />
    </Box>
  )
}

export default Icon
