import React from "react"
import { Box } from "~common/components/Primitives"
import Section from "~common/components/Section"
import Typography from "~common/components/Typography"
import Testimonial from "~common/components/Testimonial"

const Testimonials = () => (
  <Section bg="transparent" color="secondary">
    <Section.Body sx={{ pb: [5, 5, 6] }}>
      <Typography
        variant="capitalized3"
        sx={{
          textAlign: "center",
          color: "secondary",
          mx: "auto",
        }}
      >
        What they say about us
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "1fr",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ],
          alignItems: "start",
          justityItems: "center",
          gridGap: [4, 5],
          mx: "auto",
          my: [4, 5],
          px: [4, 5, "auto"],
          width: ["100%", "100%", "90%", "80%"],
        }}
      >
        <Testimonial>
          <Testimonial.Text>
            Having your past and your universe in your living room is a kind of
            a dream and you can make it with a click!
          </Testimonial.Text>
          <Testimonial.Author>Valentina</Testimonial.Author>
        </Testimonial>
        <Testimonial
          sx={{
            gridRow: ["auto", 2, 2, "auto"],
            gridColumn: ["auto", "1 / 3", "1 / 3", "auto"],
            justifySelf: "center",
          }}
        >
          <Testimonial.Text>
            I was looking for the perfect gift for my friend who is a huge
            astronomy nerd and found Almagesto. I'm impressed with the way it
            was designed and the concept of switching the perspective on the
            Solar System is literally from another planet!
          </Testimonial.Text>
          <Testimonial.Author>Luca</Testimonial.Author>
        </Testimonial>
        <Testimonial sx={{ gridRow: ["auto", 1, 1, "auto"] }}>
          <Testimonial.Text>
            Who knew that some orbital patterns would make for an awesome,
            minimal wall art? Apparently you did! Great job!
          </Testimonial.Text>
          <Testimonial.Author>David</Testimonial.Author>
        </Testimonial>
      </Box>
    </Section.Body>
    <Section.Footer logo color="secondary" />
  </Section>
)

export default Testimonials
