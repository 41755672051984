import { useStaticQuery, graphql } from "gatsby"

const useProducts = () => {
  const { productsData, imagesData } = useStaticQuery(graphql`
    query {
      productsData: allProduct {
        edges {
          node {
            id
            name
            title
            description
            url
            price
            discount
            image
            size
            customizable
          }
        }
      }

      imagesData: allFile(
        filter: { relativePath: { glob: "products/*/*.jpg" } }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const productsList = productsData.edges.map(({ node }) => ({
    ...node,
  }))

  productsList.forEach(product => {
    switch (product?.id) {
      case "custom_poster_30x40cm":
        product.layout = "poster-sm"
        break
      case "custom_poster_50x70cm":
        product.layout = "poster-md"
        break
      case "custom_poster_61x91cm":
        product.layout = "poster-lg"
        break
      default:
        break
    }
  })

  productsList.forEach((product, i) => {
    const { id } = product

    const featuredImage = imagesData.edges.find(
      ({ node: { relativePath } }) =>
        relativePath === `products/${id}/featured.jpg`
    ).node.childImageSharp.gatsbyImageData

    const gallery = imagesData.edges
      .filter(({ node: { relativePath } }) =>
        relativePath.startsWith(`products/${id}`)
      )
      .sort((a, b) => (a.node.name === "featured" ? -1 : 0))
      .map(({ node }) => node.childImageSharp.gatsbyImageData)

    productsList[i] = {
      ...product,
      featuredImage,
      gallery,
    }
  })

  const productsMap = Object.assign(
    {},
    ...productsList.map(product => {
      const { id } = product
      return { [id]: product }
    })
  )

  const getProductById = productId => productsMap[productId]

  return { productsList, productsMap, getProductById }
}

export default useProducts
